import React, {useEffect, useState} from 'react';
import {IntelligenceSection, Wrapper} from './OperationIntelligence.style';
import brainImage from '../../../public/image/whyUs/Intelligence/brain.webp';
import growth from '../../../public/image/whyUs/Intelligence/grow.webp';
import before from '../../../public/image/whyUs/Intelligence/before.webp';
import cam from '../../../public/image/whyUs/Intelligence/cam.webp';
import money from '../../../public/image/whyUs/Intelligence/money.webp';
// import meter from '../../../public/image/whyUs/Intelligence/meter.webp';
import meter from '../../../public/image/whyUs/Intelligence/meter.png';
// import perUnit from '../../../public/image/whyUs/Intelligence/per-unit.webp';
import perUnit from '../../../public/image/whyUs/Intelligence/per-unit.png';

import box from '../../../public/image/whyUs/Intelligence/box.webp';
import key from '../../../public/image/whyUs/Intelligence/key.webp';
import laptop from '../../../public/image/whyUs/Intelligence/labtop.webp';

const OperationIntelligence = () => {
  const [paraWidth, setParaWidth] = useState(32);
  useEffect(() => {
    if (window.innerWidth >= 1680 && window.innerWidth <= 1900) {
      setParaWidth(45);
    }
    if (window.innerHeight <= 768) {
      setParaWidth(50);
    }
  }, []);
  return (
    <Wrapper>
      <div className='intelligence__header'>
        <img src={brainImage.src} alt='header' />
      </div>

      {/*  start of  section */}
      <IntelligenceSection
        setHeight={14}
        setPadding={[4, 19]}
        bgColor='#11112F'
        hColor='#fff'
        pColor='#fff'
        pWidth={`${paraWidth}vw`}
        pFamily='poppinsRegular'>
        <div className='intelligence__section__header'>
          <img src={growth.src} alt='grow' />
          <h3>
            Live
            <br />
            Productivity
            <br />
            Analysis
          </h3>
        </div>
        <div className='intelligence__section__paragraph'>
          <p>
            The maximization of output predictability is guaranteed through the
            live productivity analysis that the{' '}
            <span className='intelligence__section__paragraph--colored'>
              “LMS”
            </span>{' '}
            model provides.
          </p>
          <p>
            The{' '}
            <span className='intelligence__section__paragraph--colored'>
              “LMS”
            </span>{' '}
            model facilitates{' '}
            <span>
              live visibility and precise assessment of effective hours compared
              with ineffective ones
            </span>{' '}
            and help in the identification of quality error stimulants through
            the utilization of various effectiveness parameters , such as:
          </p>
        </div>
        <div className='intelligence__section__side'>
          <div className='intelligence__section__side__item'>
            <img src={before.src} alt='before' />
            <span>
              Total{' '}
              <span className='intelligence__section__side__item--bold'>
                Hour Contribution
              </span>
            </span>
          </div>
          <div className='intelligence__section__side__item'>
            <img src={before.src} alt='before' />
            <span>
              Total{' '}
              <span className='intelligence__section__side__item--bold'>
                Rework Hours
              </span>
            </span>
          </div>
          <div className='intelligence__section__side__item'>
            <img src={before.src} alt='before' />
            <span>
              First Pass <b>YIELD</b>
            </span>
          </div>
        </div>
        {/*  end of  section */}
      </IntelligenceSection>
      {/* end of section */}

      {/*  start of  section */}
      <IntelligenceSection
        setHeight={10}
        setPadding={[3, 19]}
        bgColor='#E5E5E5'
        hColor='#11112F'
        pColor='#3C3C3B'
        pWidth={`calc( ${paraWidth}vw + 2rem + 125px)`}
        pFamily='poppinsRegular'>
        <div className='intelligence__section__header'>
          <img src={money.src} alt='grow' />
          <h3>
            Quality
            <br />
            Standardization
            <br />
            Methodology
          </h3>
        </div>
        <div className='intelligence__section__paragraph'>
          <p>
            Consistency of product quality is ensured through our quality
            standardization methodology powered by internal quality control
            guidelines & multiple, interdepartmental auditing layers, which
            enhance operational effectiveness.
          </p>
        </div>
        {/*  end of  section */}
      </IntelligenceSection>
      {/* end of section */}

      {/*  start of  section */}
      <IntelligenceSection
        setHeight={13}
        setPadding={[3.5, 19]}
        bgColor='transparent'
        hColor='#fff'
        pColor='#fff'
        pWidth={`calc( ${paraWidth}vw + 2rem + 125px)`}
        pFamily='poppinsRegular'>
        <div className='intelligence__section__header'>
          <img src={cam.src} alt='grow' />
          <h3>
            Profitability
            <br />
            Maximization
            <br />
            Analytics
          </h3>
        </div>
        <div className='intelligence__section__paragraph'>
          <p>
            The profitability maximization analytics help your business reach
            its full profitability potentials through the utilization of
            multiple cost management parameters, including:
          </p>
          <div className='intelligence__section__paragraph__images'>
            <img src={meter.src} alt='meter' />
            <img src={perUnit.src} alt='per unit' />
          </div>
        </div>
        {/*  end of  section */}
      </IntelligenceSection>
      {/* end of section */}

      {/*  start of  section */}
      <IntelligenceSection
        setHeight={11}
        setPadding={[3, 19]}
        bgColor='#E5E5E5'
        hColor='#11112F'
        pColor='#11112F'
        pWidth={`calc( ${paraWidth}vw + 2rem + 125px)`}
        pFamily='poppinsRegular'>
        <div className='intelligence__section__header'>
          <img src={box.src} alt='grow' />
          <h3>
            Optimized
            <br />
            Product Value
          </h3>
        </div>
        <div className='intelligence__section__paragraph'>
          <div>
            <p>
              {' '}
              The conduction of product workflow mapping identifies any wasted
              efforts to manage them adequately and optimize each product’s
              value.
            </p>
            <p>
              With the <span>“LMS”</span> Models, your decision-making process
              relies on the firm foundation of multidimensional{' '}
              <span> KPIs analysis</span> through
              <span> multiple, powerful statistical methods.</span>
            </p>
          </div>
        </div>
        {/*  end of  section */}
      </IntelligenceSection>
      {/* end of section */}

      {/*  start of  section */}
      <IntelligenceSection
        setHeight={11}
        setPadding={[4, 19]}
        bgColor='#11112f'
        hColor='#fff'
        pColor='#fff'
        pWidth={`calc( ${paraWidth}vw + 2rem + 125px)`}
        pFamily='poppinsRegular'>
        <div className='intelligence__section__header'>
          <img src={key.src} alt='grow' />
          <h3>
            Live
            <br />
            Performance
            <br />
            Evaluation
          </h3>
        </div>
        <div className='intelligence__section__special__paragraph'>
          <img src={laptop.src} alt='' />
          <p className='intelligence__section__special__paragraph__warper'>
            Now, you can conduct a seamless, accurate, and live evaluation of
            each stakeholder’s responsibility within specific time intervals to
            optimize operations streamline through the usage of our{' '}
            <span> Elemental </span> model.
          </p>
        </div>
        {/*  end of  section */}
      </IntelligenceSection>
      {/* end of section */}
    </Wrapper>
  );
};

export default OperationIntelligence;
