/**
 *
 * @param {NUMBER} activeSection when i will go to
 * @param {HTML ELEMENT} wrapper the main element
 * @param {NUMBER} number
 * @param {TRUE} isVertical
 */
export const scrollTo = (activeSection, wrapper, number, isVertical) => {
  // to scroll by one and ignoring any thing in tablet and mobile
  if (window.innerWidth <= 768) {
    if (activeSection === -1) return;
    wrapper.scrollTo({
      top: window.innerWidth * (activeSection + 1),
      behavior: 'smooth',
    });
  }

  // desktop screen fn
  else {
    if (activeSection === 0) {
      wrapper.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else if (activeSection > 0) {
      //all the sections
      if (number === 0.4 || number === 1) {
        wrapper.scrollTo({
          top:
            window.innerWidth +
            window.innerWidth * (number * (activeSection - 1)) -
            80,
          behavior: 'smooth',
        });
      } else {
        // why us section
        wrapper.scrollTo({
          top:
            window.innerWidth +
            window.innerWidth * 0.36 +
            window.innerWidth +
            window.innerWidth * (number * (activeSection - 1)) -
            80,
          behavior: 'smooth',
        });
      }
    }

    // to scroll up when close the section
    isVertical &&
      window.scroll({
        top: 0,
      });
  }
};
