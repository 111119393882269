import styled from 'styled-components';

export const OurServicesHome = styled.div`
  * {
    padding: 0;
    margin: 0;
  }
  img {
    transition: transform ease-in-out 450ms;
  }
  :hover {
    @media (min-width: 1025px) {
      img {
        transform: scale(1.08);
      }
      .main-title {
        transform: scale(1.03);
      }
    }
  }
  position: relative;
  overflow: hidden;
  background-color: #009992;
  height: 100%;
  .ourservices__question {
    height: 70vh;
    position: absolute;
    top: 8vh;
    right: 3.5vw;
    z-index: 1;
    @media (max-width: 1550px) {
      height: 68vh;
    }
    @media (max-width: 1440px) {
      height: 62vh;
      top: 10vh;
    }
    @media (max-width: 1366px) {
      height: 55vh;
      top: 15vh;
    }
    @media (max-width: 1024px) {
      height: 60vh;
      top: 10vh;
    }
    @media (max-width: 768px) {
      top: 17vh;
      right: 20vw;
      height: 54vh;
    }

    @media (max-width: 480px) {
      height: 48vh;
      top: 12vh;
      right: 5vw;
    }
    @media (max-width: 400px) {
      right: 0vw;
    }
  }
  .home__ourservices--hand {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 3rem;
    z-index: 1;
    @media (max-width: 1024px) and (min-width: 769px) {
      width: 20px;
      bottom: 4rem;
    }
    @media (max-width: 768px) {
      bottom: 6.5rem;
    }
    @media (max-width: 480px) {
      width: 6%;
      bottom: 13.5vh;
    }
    @media (max-width: 480px) and (max-height: 667px) {
      bottom: 12.5vh;
      @supports (-webkit-touch-callout: none) {
        bottom: 15vh;
      }
    }
    @media (max-width: 375px) and (max-height: 667px) {
      bottom: 12.5vh;
    }
  }
  .main-title {
    font-family: coolvetica_rg;
    font-size: 15rem;
    color: #fdf48e;
    line-height: 0.6;
    position: relative;
    left: -1.5rem;
    top: 34vh;
    transition: transform ease-in-out 350ms;
    @media (max-width: 1690px) {
      font-size: 13.5rem;
    }
    @media (max-width: 1550px) {
      font-size: 12rem;
    }
    @media (max-width: 1440px) {
      font-size: 11.67rem;
    }
    @media (max-width: 768px) {
      font-size: 19.75rem;
      left: -1rem;
    }
    @media (max-width: 480px) {
      font-size: 7.07625rem;
      top: 32vh;
      left: -0.5rem;
    }
    @media (max-width: 480px) and (max-height: 667px) {
      top: 25vh;
    }
    @media (max-width: 375px) and (max-height: 667px) {
      top: 28vh;
    }
  }
  .home__ourservices--description {
    position: absolute;
    width: 76%;
    top: 80vh;
    left: 11%;
    z-index: 2;
    color: #ffffff;
    text-align: justify;
    @media (max-width: 1680px) {
      width: 82%;
    }
    @media (max-width: 1550px) and (max-height: 722px) {
      top: 77vh;
    }
    @media (max-width: 1024px) {
      width: 86%;
      top: 75vh;
      left: 7%;
    }
    @media (max-width: 768px) {
      width: 80%;
      top: 70vh;
      text-align: start;
    }
    @media (max-width: 480px) {
      width: 80%;
      top: calc(68vh - 60px);
      left: 7%;
    }
    @media (max-width: 480px) and (max-height: 926px) {
      top: calc(72vh - 60px);
    }
    @media (max-width: 480px) and (max-height: 800px) {
      top: calc(70vh - 60px);
    }
    @media (max-width: 480px) and (max-height: 667px) {
      top: calc(65vh - 60px);
    }
    @media (max-width: 375px) and (max-height: 667px) {
      top: calc(68vh - 60px);
    }
    .home__ourservices--sub-title {
      font-family: poppinsBold;
      font-size: 1.875rem;
      line-height: 1;
      @media (max-width: 768px) {
        font-size: 2rem;
      }
      @media (max-width: 480px) {
        font-size: 1.375rem;
      }
    }
    .home__ourservices--content {
      font-family: poppinsRegular;
      font-size: 1rem;
      @media (max-width: 1440px) {
        font-size: 0.8rem;
      }
      @media (max-width: 1024px) {
        font-size: 1.2rem;
      }
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
      @media (max-width: 480px) {
        font-size: 0.8125rem;
      }
    }
  }
`;

export const OurServicesExpanded = styled.div`
  * {
    padding: 0;
    margin: 0;
  }
  background-color: #078a83;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .question_opacity {
    height: 110vh;
    top: -5vh;
    position: absolute;
    left: 13%;
    @media (max-width: 768px) {
      width: 100vw;
      height: auto;
      left: unset;
    }
  }
  .factory {
    width: 100%;
    position: absolute;
    bottom: 0;
    @media (max-width: 768px) {
      z-index: 1;
    }
  }
`;

export const OurServicesContent = styled.div`
  position: absolute;
  width: 80%;
  display: flex;
  flex-direction: column;
  left: 9%;
  top: 17%;
  @media (max-width: 1680px) {
    width: 85%;
    left: 4%;
  }
  @media (max-width: 1550px) {
    top: 5%;
  }

  @media (max-width: 1440px) {
    width: 90%;
    top: 10%;
  }
  @media (max-width: 768px) {
    width: 90%;
    text-align: justify;
    top: 14%;
    z-index: 2;
  }
  @media (max-width: 480px) {
    width: 75%;
    left: 0;
    right: 0;
    margin: auto;
  }
  @media (max-width: 480px) and (max-height: 736px) {
    width: 82%;
  }
  .home__ourservices {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .home__ourservices--container {
    color: #fff;
    width: 51.5%;
    @media (max-width: 768px) {
      width: 100%;
    }
    &:nth-of-type(2n) {
      width: 45%;
      @media (max-width: 768px) {
        width: 100%;
        margin-top: 3rem;
      }
      @media (max-width: 480px) {
        margin-top: 1.5rem;
      }
    }
    &-title {
      font-family: coolvetica_rg;
      font-size: 6.28625rem;
      line-height: 0.65;
      @media (max-width: 1550px) {
        font-size: 5.5rem;
      }
      @media (max-width: 1440px) {
        font-size: 5.08625rem;
      }
      @media (max-width: 480px) {
        font-size: 2.5rem;
      }
      span {
        font-size: 3.666875rem;
        @media (max-width: 1550px) {
          font-size: 3.25rem;
        }
        @media (max-width: 1440px) {
          font-size: 2.466875rem;
        }
        @media (max-width: 480px) {
          font-size: 1.625rem;
        }
      }
    }
    &-content {
      margin-top: 2rem;
      font-family: poppinsBold;
      font-size: 1.25rem;
      @media (max-width: 1680px) {
        line-height: 1.3;
      }
      @media (max-width: 1550px) {
        line-height: normal;
        font-size: 1.05rem;
        margin-top: 1rem;
      }

      @media (max-width: 1440px) {
        font-size: 1rem;
      }
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
      @media (max-width: 480px) {
        font-size: 0.8125rem;
        margin-top: 0.5rem;
      }
    }
  }
  .home__ourservices--button {
    position: relative;
    top: 2vh;
    /* left: 0.5rem; */
    z-index: 1;
    width: fit-content;
    cursor: pointer;
    @media (max-width: 1680px) {
      top: 4vh;
    }
    @media (max-width: 1550px) {
      top: 2vh;
    }
    @media (max-width: 768px) {
      left: 0;
      top: 5vh;
    }
    @media (max-width: 480px) {
      top: 3vh;
    }
    &-showmore {
      background-color: #fbf28c;
      color: #078a83;
      border-radius: 10px;
      padding: 10px 20px;
      border: none;
      font-family: poppinsBold;
      font-size: 1rem;
      width: fit-content;
      margin: 0;
      cursor: pointer;
      @media (max-width: 480px) {
        font-size: 0.6rem;
        border-radius: 6px;
        padding: 8px 16px;
      }
    }
  }
`;
