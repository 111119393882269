import styled from "styled-components";
import Bg from "../../../public/image/whyUs/security/back.webp";

export const Wrapper = styled.div`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  background-image: url(${Bg.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom left;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100vh;
  position: relative;
  overflow: hidden;

  @media (max-height: 700px), (max-width: 1440px) {
    font-size: 0.7rem;
  }

  .title {
    background-size: cover;
    background-position: top right;
    text-align: center;
    position: relative;
    min-height: 29vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-height: 800px) {
      min-height: 17vh;
    }
    img {
      position: relative;
      max-width: 17vw;
      top: 5%;
      width: 280px;
      margin-bottom: 10px;
    }
  }

  .lower {
    letter-spacing: -0.3px;
    display: flex;
    position: relative;
    padding: 2vh 10vw;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;
    margin-bottom: 7vh;
    @media (max-width: 1536px) {
      padding: 2vh 6vw;
    }
    img {
      @media (max-width: 1550px) {
        height: 45px;
      }
      @media (max-height: 768px) and (max-width: 1280px) {
        height: 40px;
      }
    }
    article {
      display: flex;
      margin: 5px;
      flex-wrap: wrap;
      flex-basis: 29%;
      margin: 0px 1%;
      align-items: baseline;
      flex-direction: column;
      .cardtop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 5.1875rem;
        @media (max-width: 1536px) {
          height: 3.1875rem
        }
        @media (max-height: 700px), (max-width: 1440px) {
          margin-bottom: 0;
        }
        @media (max-width: 1440px) {
          height: 4rem;
        }
      }
    }
  }

  .content {
    /* font-size: var(--font-size-small); */
    font-size: 0.9rem;
    font-family: poppinsRegular;
    color: #3c3c3b;
    text-align: justify;
    line-height: 1rem;

    p:not(:first-child) {
      margin-top: 10px;
    }

    .colored {
      color: #11112f;
    }

    @media (max-width: 1680px) {
      font-size: 0.9rem;
      line-height: 1.1;
    }
    @media (max-width: 1440px) {
      font-size: 0.8rem;
      line-height: 0.9rem;
    }
    @media (max-height: 740px) {
      font-size: 0.75rem;
    }

    @media (max-height: 740px) and (max-width: 1280px) {
      font-size: 0.9rem;
    }
    @media (max-height: 650px) and (max-width: 1280px) {
      font-size: 0.85rem;
    }
    @media (max-height: 768px) and (max-width: 1024px) {
      font-size: 1.2rem;
      line-height: 1.2;
    }

    /* @media (max-width: 1700px) {
      font-size: var(--font-size-normal);
    }
    @media (max-height: 700px) {
      font-size: 1rem;
    }
    @media (max-width: 1600px) and (min-width: 1300px) {
      font-size: 0.7rem;
    }
    @media (max-width: 1300px) and (min-width: 1100px) {
      font-size: 0.8rem;
    } */
    span {
      font-family: poppinsBold;
    }
  }

  .header {
    font-size: 1.2rem;
    font-family: poppinsBold;
    color: #11112f;
    justify-content: flex-start;
    margin-bottom: 5px;
    max-width: 19vw;
    @media (max-width: 1680px) {
      line-height: 1;
    }
    @media (max-width: 1440px) {
      font-size: 1rem !important;
    }
    @media (max-height: 800px) {
      font-size: 1.1rem;
    }
    @media (max-height: 740px) {
      font-size: 1rem;
    }
    @media (max-height: 740px) and (max-width: 1280px) {
      font-size: 1.2rem;
    }
    @media (max-height: 650px) and (max-width: 1280px) {
      font-size: 1rem;
    }

    @media (max-height: 768px) and (max-width: 1024px) {
      font-size: 1.45rem;
    }
  }

  @keyframes security {
    from {
      transform: scale(1.1);
    }
    to {
      transform: scale(1);
    }
  }
`;
