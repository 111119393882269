import {useEffect, useRef, useState} from 'react';
import IntroPage from '../components/Home/IntroPage/IntroPage';
import AboutUs from '../components/Home/AboutUs';
import {OuterWrapper, Wrapper} from '../styles/Home.style';
import WhyUs from '../components/Home/WhyUs/WhyUs';
import OurServices from '../components/Home/OurServices';
import ContactUs from '../components/Home/ContactUs';
import {scrollTo} from '../util/scroll';

import {useDispatch, useSelector} from 'react-redux';
import {
  selectActiveSectionIndex,
  selectWhyActiveSectionIndex,
  setWhyActive,
} from '../core/state/navigation.slice';
import {Global} from '../styles/Gloable.styles';
import OurPackage from '../components/Home/OurPackage';
import * as _ from 'lodash';
import {swipeLeft, swipeRight} from '../core/state/currentIndex';

export default function Home() {
  const dispatch = useDispatch();
  const activeSection = useSelector(selectActiveSectionIndex);
  const whyActive = useSelector(selectWhyActiveSectionIndex);
  const outerWrapper = useRef();
  const wrapper = useRef();
  const [sectionNumber, setSectionNumber] = useState(6);
  const currentIndex = useSelector((state) => state.currentIndex.currentIndex);
  let currentSection = currentIndex;

  useEffect(() => {
    outerWrapper.current.scrollTo(0, 0);
  }, [outerWrapper.current]);

  useEffect(() => {
    scrollTo(activeSection, outerWrapper.current, 0.4);
    activeSection >= 1 && dispatch(setWhyActive(-1));
    if (activeSection >= 1) {
      dispatch(swipeRight());
      setSectionNumber(7);
      currentSection++;
    } else {
      setSectionNumber(6);
    }
  }, [activeSection]);

  useEffect(() => {
    scrollTo(whyActive, outerWrapper.current, 0.05);
  }, [whyActive]);

  let startScroll = 0;

  const handleTouchMove = (e) => {
    startScroll = e.touches[0].clientX;
    setTimeout(() => {
      startScroll = 0;
    }, 300);
  };
  const handleTouchEnd = (e) => {
    if (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    ) {
      return;
    } else {
      if (startScroll !== 0) {
        if (e.changedTouches[0].clientX > startScroll) {
          currentSection > 0 && dispatch(swipeLeft());
          currentSection > 0 && currentSection--;
          outerWrapper.current.scrollTo(0, window.innerWidth * currentSection);
        } else {
          currentSection < sectionNumber && dispatch(swipeRight());
          currentSection < sectionNumber && currentSection++;
          outerWrapper.current.scrollTo(0, window.innerWidth * currentSection);
        }
      }
    }
    startScroll = 0;
  };

  return (
    <OuterWrapper
      ref={outerWrapper}
      onTouchMove={_.throttle(handleTouchMove, 300)}
      onTouchEnd={handleTouchEnd}>
      <Wrapper ref={wrapper}>
        <IntroPage />
        <AboutUs />
        <WhyUs />
        <OurServices />
        <OurPackage />
        <ContactUs />
      </Wrapper>
      <Global />
    </OuterWrapper>
  );
}
