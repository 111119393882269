import styled from 'styled-components';
import bg from '../../../public/image/whyUs/Intelligence/mainbg.webp';

export const Wrapper = styled.div`
  background: url(${bg.src});
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .intelligence__header {
    display: flex;
    justify-content: center;
    align-items: baseline;
    img {
      margin-top: 7vh;
      margin-bottom: 1rem;
    }
    @media (max-width: 1680px) {
      img {
        margin-top: 3vh;
        margin-bottom: 1rem;
        width: 10vw;
      }
    }
    @media (max-width: 1440px) {
      img {
        height: 100px;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
`;

export const IntelligenceSection = styled.section`
  width: 100%;
  height: ${(props) => props.setHeight}vh;
  background-color: ${(props) => props.bgColor};
  display: flex;
  align-items: center;
  padding: ${(props) => props.setPadding[0]}vw
    ${(props) => props.setPadding[1]}vw;

  .intelligence__section {
    &__header {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      gap: 1rem;
      width: 19rem;
      img {
        height: 100%;
      }
      h3 {
        font-family: poppinsBold;
        font-size: 1.43rem;
        font-weight: 400;
        text-transform: capitalize;
        color: ${(props) => props.hColor};
        line-height: 1;
      }
    }
    &__paragraph {
      display: flex;
      flex-direction: column;
      padding: 1rem 0;
      gap: 0.75rem;
      width: ${(props) => props.pWidth};
      font-family: poppinsRegular;
      font-size: 0.85rem;

      &--colored {
        color: #eb8a00;
        font-size: 0.95rem;
      }
      &__images {
        display: flex;
        gap: 1rem;
      }
      p {
        color: ${(props) => props.pColor};
        padding: 0;
        margin: 0;
        text-align: justify;
        span {
          font-family: poppinsBold;
        }
      }
    }
    &__special__paragraph {
      display: flex;
      gap: 1rem;
      color: #fff;
      font-family: poppinsRegular;
      width: ${(props) => props.pWidth};
      align-items: center;
      img {
        height: 100%;
      }
      &__warper {
        padding: 1rem 0;
        margin: 0;
        text-align: justify;
        span {
          font-family: poppinsBold;
        }
      }
    }
    &__side {
      display: flex;
      flex-direction: column;
      gap: 0.15rem;
      margin-left: 2rem;
      /* width: 125px; */
      &__item {
        display: flex;
        gap: 0.25rem;
        width: 7rem;
        &--bold {
          font-family: poppinsBold;
          text-transform: uppercase;
          display: block;
          font-size: 0.8rem;
        }
        img {
          height: 100%;
          margin-top: 5px;
        }
        span {
          color: #fff;
          line-height: 1rem;
          white-space: nowrap;
          margin-top: 4px;
          @media (max-width: 1550px) {
            line-height: 1 !important;
          }
        }
      }
    }
  }

  @media (max-width: 1680px) {
    padding: ${(props) => props.setPadding[0] + 0.5}vw
      ${(props) => props.setPadding[1] - 3}vw;
    .intelligence__section {
      &__header {
        width: 17rem;
        img {
          height: 60px;
        }
        h3 {
          font-size: 1.25rem;
        }
      }
      &__paragraph {
        width: ${(props) => props.pWidth};
        font-size: 1rem;

        p {
          span {
            /* color: #eb8a00; */
            /* font-size: 1rem; */
          }
        }
      }
      &__special__paragraph {
        img {
        }
        p {
        }
      }
      &__side {
        &__item {
          width: 10rem;
          gap: 0.4rem;
          img {
          }
          span {
          }
        }
      }
    }
  }

  @media (max-width: 1680px) and (max-height: 800px) {
    padding: ${(props) => props.setPadding[0]}vw
      ${(props) => props.setPadding[1] - 4}vw;
    .intelligence__section {
      &__header {
        width: 17rem;
        img {
          height: 60px;
        }
        h3 {
          font-size: 1.25rem;
        }
      }
      &__paragraph {
        width: ${(props) => props.pWidth};
        font-size: 0.9rem;

        p {
          span {
            /* color: #eb8a00; */
            /* font-size: 1rem; */
          }
        }
      }
      &__special__paragraph {
        img {
        }
        p {
        }
      }
      &__side {
        &__item {
          width: 10rem;
          gap: 0.4rem;
          img {
          }
          span {
          }
        }
      }
    }
  }

  @media (max-width: 1517px) {
    padding: ${(props) => props.setPadding[0] + 0.85}vw
      ${(props) => props.setPadding[1] - 6}vw;
    .intelligence__section {
      &__header {
        width: 14rem;
        img {
          height: 45px;
        }
        h3 {
          font-size: 1rem;
        }
      }
      &__paragraph {
        width: ${(props) => props.pWidth + 28};
        font-size: 0.85rem;
        p {
          span {
            /* color: #eb8a00; */
            /* font-size: 0.9rem; */
          }
        }
        &__images {
          img {
            height: 40px;
          }
        }
      }
      &__special__paragraph {
        img {
          height: 70px;
        }
        p {
          font-size: 0.85rem;
        }
      }
      &__side {
        &__item {
          img {
            height: 10px;
          }
          span {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  @media (max-width: 1550px) {
    .intelligence__section {
      &__paragraph {
        font-size: 0.85rem !important;
      }
    }
  }

  @media (max-width: 1440px) {
    .intelligence__section {
      &__paragraph {
        font-size: 0.75rem !important;
      }
      &__side {
        &__item {
          img {
            height: 15px;
          }
          span {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    padding: ${(props) => props.setPadding[0] + 0.85}vw
      ${(props) => props.setPadding[1] - 6}vw;
    .intelligence__section {
      &__header {
        width: 14rem;
        img {
          height: 45px;
        }
        h3 {
          font-size: 1rem;
        }
      }
      &__paragraph {
        width: ${(props) => props.pWidth + 28};
        font-size: 0.85rem;
        p {
          span {
            /* color: #eb8a00; */
            /* font-size: 0.9rem; */
          }
        }
        &__images {
          img {
            height: 40px;
          }
        }
      }
      &__special__paragraph {
        img {
          height: 70px;
        }
        p {
          font-size: 0.85rem;
        }
      }
      &__side {
        &__item {
          img {
            height: 10px;
          }
          span {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  @media (max-height: 768px) {
    /* padding: ${(props) => props.setPadding[0] + 0.4}vw */
    padding: ${(props) => props.setPadding[0] + 0.2}vw
      ${(props) => props.setPadding[1] - 9}vw
      ${(props) => props.setPadding[0] + 0.2}vw
      ${(props) => props.setPadding[1] - 4}vw;
    .intelligence__section {
      &__header {
        width: 14rem;
        img {
          height: 50px;
        }
        h3 {
          font-size: 1.1rem;
        }
      }
      &__paragraph {
        width: ${(props) => props.pWidth};
        font-size: 0.9rem;
        p {
          span {
            /* color: #eb8a00; */
            /* font-size: 1rem; */
          }
        }
        &__images {
          img {
            height: 45px;
          }
        }
      }
      &__special__paragraph {
        img {
          height: 60px;
        }
        p {
          font-size: 0.9rem;
        }
      }
      &__side {
        &__item {
          width: 12rem;
          img {
          }
          span {
          }
        }
      }
    }
  }

  /* @media (max-height: 740px) {
    padding: ${(props) => props.setPadding[0] + 0.45}vw
      ${(props) => props.setPadding[1] - 4}vw;
  } */

  /* @media (max-height: 700px) {
    padding: ${(props) => props.setPadding[0] + 0.2}vw
      ${(props) => props.setPadding[1] - 4}vw;
  } */

  @media (max-height: 670px) {
    padding: ${(props) => props.setPadding[0] - 0.1}vw
      ${(props) => props.setPadding[1] - 2}vw;
    .intelligence__section {
      &__header {
        width: 14rem;
        img {
          height: 50px;
        }
        h3 {
          font-size: 0.9rem;
        }
      }
      &__paragraph {
        width: ${(props) => props.pWidth};
        font-size: 0.75rem;
        margin-top: 5px;
        p {
          span {
            /* color: #eb8a00; */
            /* font-size: 1rem; */
          }
        }
        &__images {
          img {
            height: 45px;
          }
        }
      }
      &__special__paragraph {
        img {
          height: 60px;
        }
        p {
          font-size: 0.9rem;
        }
      }
      &__side {
        &__item {
          width: 12rem;
          img {
          }
          span {
          }
        }
      }
    }
  }

  @media (max-height: 768px) and (max-width: 1024px) {
    height: ${(props) => props.setHeight + 4}vh;
    .intelligence__section {
      &__header {
        width: 17.5rem;
        img {
          /* height: 100%; */
        }
        h3 {
          font-size: 1.25rem;
        }
      }
      &__paragraph {
        width: ${(props) => props.pWidth};
        &__images {
          display: flex;
          gap: 1rem;
        }
        p {
          font-size: 1.3rem;
          span {
            font-size: 1.15rem;
          }
        }
      }
      &__special__paragraph {
        img {
          height: 60px;
        }
        &__warper {
          font-size: 1.3rem !important;
          p {
            font-size: 1.3rem !important;
          }
        }
      }
      &__side {
        &__item {
          width: 7rem;
          img {
          }
          span {
            font-size: 1.2rem;
            line-height: 1.5rem;
            span {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
`;
