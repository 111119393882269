import React from 'react';
import {Wrapper} from './OperationExcellence.style';
import Title from '../../../public/image/WhyUs/Operational/title.webp';
import Bg from '../../../public/image/WhyUs/Operational/bg.webp';
import Hbg from '../../../public/image/WhyUs/Operational/chess.webp';
import Shape1 from '../../../public/image/WhyUs/Operational/shape1.webp';
import Shape2 from '../../../public/image/WhyUs/Operational/shape2.webp';
import Shape3 from '../../../public/image/WhyUs/Operational/shape3.webp';
import Shape4 from '../../../public/image/WhyUs/Operational/shape4.webp';
import Shape5 from '../../../public/image/WhyUs/Operational/shape5.webp';

const OperationExcellence = () => {
  return (
    <Wrapper style={{backgroundImage: `url(${Bg.src})`}}>
      <div className='upper'>
        <div className='title' style={{backgroundImage: `url(${Hbg.src})`}}>
          <img src={Title.src} alt='' className='titleanimate' />
        </div>
      </div>
      <div className='lower'>
        <div className='colored' />
        <div className='overarticle'>
          <article>
            <div className='headerImg'>
              <img src={Shape1.src} />
            </div>
            <div className='header'>
              Function-Based <br />
              Performance Analyses
            </div>
            <div className='content'>
              <p>
                The <span className='content-color'>“LMS”</span> model
                guarantees an optimized value of each employee’s contribution
                through the conduction of
                <span>
                  {' '}
                  multi-tier, function-based performance analyses to achieve
                  extensive operational excellence.
                </span>
              </p>
            </div>
          </article>
          <article>
            <div className='headerImg'>
              <img src={Shape2.src} />
            </div>
            <div className='header'>Multi-Layered Visibility</div>
            <div className='content'>
              <p>
                Our periodic reports of performance parameters will help you
                gain multi-layered visibility by comparing actual performance
                with planned capacities for{' '}
                <span>insightful decision making.</span>
              </p>
            </div>
          </article>
          <article>
            <div className='headerImg'>
              <img src={Shape3.src} />
            </div>
            <div className='header'>
              Objective-Based <br />
              Readiness Plans
            </div>
            <div className='content'>
              <p>
                The onboarding methodology we endorse ensures the readiness of
                new recruits with an{' '}
                <span>
                  85% target achievement rate within the first 4 weeks through
                  the implementation of objective-based readiness plans for each
                  function.
                </span>
              </p>
            </div>
          </article>
          <article>
            <div className='headerImg'>
              <img src={Shape4.src} />
            </div>
            <div className='header'>
              Technical Training <br />
              Management
            </div>
            <div className='content'>
              <p>
                <span>Our manpower sourcing model</span> ensures that our
                professionals’ capabilities seamlessly meet the changing needs
                of the market through well-structured{' '}
                <span>certification and re-certification programs</span> as part
                of our technical training management model.
              </p>
            </div>
          </article>
          <article>
            <div className='headerImg'>
              <img src={Shape5.src} />
            </div>
            <div className='header'>Operational Autonomy</div>
            <div className='content'>
              <p>
                The <span className='content-color'>“LMS”</span> model is
                governed by a multi-functional, dedicated arm that ensures{' '}
                <span>operational autonomy</span> and{' '}
                <span> output/product optimization.</span>
              </p>
            </div>
          </article>
        </div>
      </div>
    </Wrapper>
  );
};

export default OperationExcellence;
