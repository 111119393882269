import styled from "styled-components";

export const Wrapper = styled.div`
  background-repeat: no-repeat;
  background-size: 80vw;
  background-position: -60vw 0vh;
  min-height: 100vh;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100vh;

  @media (max-height: 700px) {
    font-size: 1rem;
  }

  .title {
    background-size: cover;
    background-position: center center;
    text-align: center;
    position: relative;
    min-height: 8vw;
    img {
      position: relative;
      max-width: 14vw;
      margin: -11%;
    }
  }

  .lower {
    letter-spacing: -0.3px;
    display: flex;
    flex-grow: 5;
    position: relative;
    flex-direction: column;
    /* padding-top: 8vh; */
    padding-left: 5vw;
    .colored {
      min-width: 43%;
      background-color: #006a66bf;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      z-index: -1;
    }
    .overarticle {
      justify-content: center;
      width: max-content;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      flex-grow: 5;
      &:after {
        content: "";
        background: #0d0d2bdb;
        position: absolute;
        height: 100%;
        top: 0;
        z-index: -1;
        max-width: calc(9vh + 40px);
        width: 175px;
      }
    }
    article {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      max-width: max-content;
      position: relative;
      padding: 10px 0 10px;
      margin: 0 auto;
      font-family: "nexa";
      align-items: center;
      article:first-of-type {
        padding-top: 0 !important;
        padding-bottom: 15px;
      }

      &:before {
        content: "";
        width: 69%;
        height: 1px;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &:after {
        content: "";
        width: 65%;
        height: 1px;
        background: #408f8c;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    article:last-child:after,
    article:last-child:before {
      display: none;
    }
  }

  .content {
    font-size: 1rem;
    width: 35vw;
    padding-left: 1vw;
    text-align: justify;
    font-family: poppinsRegular;
    color: #3c3c3b;
    @media (max-width: 1700px) {
      font-size: 0.9rem;
      line-height: 1.2;
      padding-left: 0;
    }
    @media (max-width: 1550px) {
      font-size: 0.9rem;
    }
    @media (max-height: 700px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1300px) {
      font-size: 1rem;
    }
    span {
      &.content-color {
        color: #11112f;
        font-size: 0.8rem;
      }
      font-family: poppinsBold;
    }

    p {
      margin: 0;
    }
  }

  .headerImg {
    margin-right: 40px;
    margin-left: 15px;
    max-width: 9vh;

    img {
      position: relative;
      left: 5px;
      width: 100%;
    }
  }

  .header {
    font-size: 1.15rem;
    font-family: poppinsBold;
    font-family: 400;
    justify-content: space-between;
    width: 278px;
    /* width: 230px; */
    padding-right: 75px;
    color: #11112f;
    line-height: 1.2rem;

    @media (max-width: 1700px) {
      font-size: 1rem;
    }
    @media (max-width: 1550px) {
        font-size: 1rem;
      }
    @media (max-height: 700px) {
      font-size: 1rem;
    }
  }

  @keyframes operational {
    0% {
      transform: rotate(0deg);
    }
    /* 10% {
      transform: rotate(360deg);
    }
    90% {
      transform: rotate(0deg);
    } */
    100% {
      transform: rotate(-360deg);
    }
  }

  /* .titleanimate {
    animation-name: operational;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    transform-origin: center;
  } */
`;
