import React from "react";
import { useSelector } from "react-redux";
import { Wrapper_Home, SolidBackground } from "./IntroPage.style";
import intro from "../../../public/image/home/intro.png";
import mouse from "../../../public/image/home/mouse.png";
import mouseM from "../../../public/image/home/mouseM.png";
import useWidth from "../../../hooks/useWidth";

const IntroPage = () => {
  const witchDevice = useSelector((state) => state.windowWidth.windowWidth);
  const width = useWidth();
  return (
    <Wrapper_Home>
      <SolidBackground>
        <img src={intro.src} alt="mouse-image" />
      </SolidBackground>
      <div className="mouse__image">
        <img src={width > 1024 ? mouse.src : mouseM.src} alt="scroll-image" />
      </div>
    </Wrapper_Home>
  );
};

export default IntroPage;
