import styled from "styled-components";
import chain from "../../../public/image/whyUs/Panoramic/chain.webp";

export const Wrapper = styled.div`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left bottom;
  min-height: 100vh;
  padding-top: 2rem;
  @media (max-height: 800px) {
    padding-top: 5px;
  }
  @media (max-width: 1024px) {
    padding-top: 10vh;
  }
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100vh;
  position: relative;

  .HR {
    &__upper {
      text-align: center;
      img {
        height: 130px;
        @media (max-height: 800px) {
          height: 100px;
        }
      }
    }

    &__warper {
      display: flex;
      flex-direction: column;
    }

    &__first {
      background-image: url(${chain.src});
      display: flex;
      flex-direction: column;
      color: #fff;

      &__main {
        /* padding: 1rem 10% 0 15%; */
        padding: 0.3rem 10% 0 15%;
        @media (max-width: 1680px) and (min-width: 1300px) {
          padding: 0.3rem 5% 0 10%;
        }

        img {
          @media (max-height: 800px) {
            height: 40px;
          }
        }
        &__content {
          display: flex;
          gap: 1rem;
          justify-content: space-between;
          &__col {
            display: flex;
            font-family: poppinsRegular;
            font-size: 0.9rem;
            flex-direction: column;
            justify-content: space-between;
            &--title {
              width: 34%;
              @media (max-width: 1536px) {
                width: 37%;
              }
              h4 {
                padding: 0;
                margin: 0;
                font-size: 1.25rem;
                font-family: poppinsBold;
                margin-bottom: 0.5rem;
                @media (max-width: 1536px) {
                  font-size: 1rem;
                }
              }
              p {
                padding: 0;
                margin: 0;
                text-align: justify;
                padding-bottom: 0.5rem;
                @media (max-width: 1680px) {
                  font-size: 1rem;
                }
                @media (max-width: 1536px) {
                  font-size: 0.8rem;
                }
              }

              span {
                color: #eb8a00;
                font-family: poppinsBold;
              }
            }

            section {
              display: flex;
              flex-direction: row;

              img {
                flex-shrink: 0;
                height: 15px;
              }
              div {
                display: flex;
                flex-direction: column;
                /* font-size: 0.9rem; */
                h5 {
                  font-family: poppinsBold;
                  font-size: 1rem;
                  @media (max-width: 1680px) {
                    font-size: 0.9rem;
                  }
                  @media (max-width: 1536px) {
                    font-size: 0.8rem;
                  }
                  &.nowrap {
                    white-space: nowrap;
                  }
                }
                span {
                  font-family: poppinsRegular;
                  font-size: 0.85rem;
                }
              }
            }
          }
        }
      }

      &__footer {
        background-color: #11112f;
        padding: 1.25rem 10% 1.25rem 15%;
        font-family: poppinsRegular;
        font-size: 0.9rem;
        text-align: justify;
        z-index: 2;
        @media (max-width: 1680px) {
          font-size: 1rem;
        }
        @media (max-width: 1536px) {
          font-size: 0.8rem;
        }
        @media (max-height: 750px) {
          padding: 0.5rem 10% 0.5rem 15%;
        }
        @media (max-width: 1680px) and (min-width: 1300px) {
          padding: 0.5rem 5% 0.5rem 10%;
        }
        &--colored {
          font-family: poppinsBold;
          color: #72beeb;
        }
        &--not-colored {
          font-family: poppinsBold;
        }
      }
    }

    &__second {
      display: flex;
      padding: 0 0 1.5rem 15%;

      @media (max-height: 740px) {
        padding: 0 0 0.5rem 15%;
      }

      @media (max-width: 1680px) and (min-width: 1300px) {
        padding: 0 0 0.5rem 10%;
      }

      &__col {
        &--first {
          color: #3c3c3b;
          padding-top: 2rem;
          padding-right: 2rem;
          flex-shrink: 0;
          max-width: 40%;
          @media (max-width: 1536px) {
            line-height: 1.1;
          }
          h4 {
            font-size: 1.25rem;
            font-family: poppinsBold;
            color: #11112f;
            margin-bottom: 0.5rem;
          }
          p {
            font-family: poppinsRegular;
            font-size: 0.9rem;
            text-align: justify;
            @media (max-width: 1680px) {
              font-size: 1rem;
            }
            @media (max-width: 1440px) {
              font-size: 0.878rem;
            }
            span {
              font-family: poppinsRegular;
            }
          }
        }
        &--orange {
          background-color: #e14e1b;
          color: #fff;
          padding: 1rem 1rem;
          margin-left: 1rem;

          &--title {
            display: flex;
            margin-bottom: 1rem;
            align-items: center;

            img {
              margin-bottom: 10px;
              margin-left: 10px;
            }
          }
          h4 {
            font-size: 1.25rem;
            font-family: poppinsBold;
          }
          p {
            font-family: poppinsRegular;
            font-size: 0.9rem;
            /* width: 150px; */
            text-align: left;
            span {
              font-family: poppinsBold;
            }
            @media (max-width: 1680px) {
              font-size: 1rem;
            }
            @media (max-width: 1440px) {
              font-size: 0.95rem;
            }
          }
        }
        &--last {
          display: flex;
          flex-direction: column;
          &--lower {
            display: flex;
            height: 100%;
          }
          img {
            /* width: 100vh; */
            max-width: 590px;
            height: 90px;
            display: block;
            object-fit: cover;
            object-position: top left;
          }
          &--upper {
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid #fff;
          }
          &--left {
            min-width: 140px;
          }
          &--right {
            min-width: fit-content;
          }
          &--overlay {
            background-color: #e14e1b;
            width: 10vw;
            height: auto;
            flex-shrink: 0;
          }
          section {
            background-color: #11112f;
            color: #fff;
            padding: 1rem 1.5rem 0.5rem 1.5rem;
            article {
              display: flex;
              flex-direction: row;
              gap: 1rem;

              div {
                display: flex;
                flex-direction: column;
                /* min-width: 217px; */
                h5 {
                  font-size: 0.8rem;
                  font-family: poppinsBold;
                  text-transform: uppercase;
                  text-align: justify;
                }
                span {
                  font-size: 0.8rem;
                  font-family: poppinsRegular;
                }
              }
            }
          }
        }
      }
    }

    &__third {
      display: flex;
      padding: 0 13vw 1rem 15%;
      @media (max-width: 1680px) and (min-width: 1300px) {
        padding: 0 5vw 1rem 10%;
      }
      position: relative;

      &__content {
        padding-top: 1rem;
        /* border-top: 2px solid #e14e1b; */
        display: flex;
        gap: 4rem;
        text-align: justify;
      }
      &__col {
        &--title {
          position: relative;
          top: -45%;
          flex-shrink: 0;
          font-family: poppinsBold;
          font-size: 1.25rem;
          color: #11112f;
          @media (max-width: 1680px) {
            top: -45%;
          }
          @media (max-width: 1536px) and (min-width: 1441px) {
            font-size: 1rem;
          }
          @media (max-width: 1024px) {
            top: -20%;
          }
        }
        &--content {
          width: 100%;
          padding-right: 5rem;
          @media (max-width: 1680px) {
            font-size: 0.95rem;
          }
          @media (max-width: 1440px) {
            font-size: 0.9rem;
          }
          @media (max-height: 700px) {
            padding-right: 0;
          }

          font-family: poppinsRegular;
          font-size: 0.9rem;
          color: #3c3c3b;
          &--colored {
            color: #11112f;
          }
          span {
            font-family: poppinsBold;
          }
        }
      }

      :before {
        content: "";
        position: absolute;
        left: 18vw;
        top: 0;
        border-top: 2px solid #e14e1b;
        width: 70%;
        @media (max-width: 1700px) {
          left: 20vw;
        }
      }
    }

    &__overlay {
      width: 10vw;
      /* height: 20vh; */
      height: 26vh;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: #e14e1b;
    }

    &__castle {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100vw;
    }

    &__tourch {
      position: absolute;
      top: 0;
      left: 0;
      max-height: 100vh;
    }
  }
`;
