import React from 'react';
import {Wrapper} from './DataSecurity.style';
import Title from '../../../public/image/whyUs/security/title.webp';
import Hbg from '../../../public/image/whyUs/security/hbg.webp';
import Arrow from '../../../public/image/whyUs/security/arrow.webp';
import Box from '../../../public/image/whyUs/security/box.webp';
import Cloud from '../../../public/image/whyUs/security/cloud.webp';
import Eye from '../../../public/image/whyUs/security/eye.webp';
import Money from '../../../public/image/whyUs/security/money.webp';
import Sec from '../../../public/image/whyUs/security/sec.webp';

const DataSecurity = () => {
  const allData = [
    {
      head: 'A Trustworthy<br />Partnership',
      image: Sec.src,
      desc: '<p>In terms of corporate code of conduct, the <span class="colored">“LMS”</span> model guarantees your  business a trustworthy partnership guided by a compliance culture that encompasses anti-bribery acts, corporate employees statements, employment rights.</p><p> The guidelines we follow are <span> reviewed and validated by various global organizations, aligning with corporate compliance best practices and reinforced by technical documentation.</span></p>',
    },
    {
      head: 'Market-Based<br />Compliance',
      image: Box.src,
      desc: '<p>A sustainable, long-term partnership awaits your business for all its manpower sourcing needs through our regulatory compliance to local laws in every market we operate in.</p> <p>Such compliance practices are <span> governed by a cross-auditing validation model and empowered by a market-based compliance and legal expertise.</span>  </p>',
    },
    {
      head: 'Exclusive Account<br />Management Model',
      image: Eye.src,
      desc: '<p>Our exclusive account management model ensures a regulated management of each partner’s account <span> to exclude any conflicts of interest and provide strict data confidentiality.</span> </p> <p>The model dedicates resources and professional capabilities to meet the specific business requirements of each account as a standalone, extended business unit.</p>',
    },
    {
      head: 'Structured<br />Confidentiality Guidelines',
      image: Arrow.src,
      desc: '<p>Complete <span> data privacy, security, and confidentiality</span> are guaranteed by the <span class="colored">“LMS”</span> model that adopts structured confidentiality guidelines and data privacy codes according to the <span> global standards.</span></p><p>Such confidentiality is reinforced by non-solicitation agreements signed by all associated internal stakeholders, and the data security management is supported by <span> three-layered security servers.</span></p> ',
    },
    {
      head: 'Three-Layered<br />Security Measurements',
      image: Money.src,
      desc: '<p><span class="colored">Electa Sourcing</span> is empowered by <span> three-layered security</span> measurements and highly-audited <span>IT infrastructure</span> that ensure maximum security of all our data sourcing and digital solutions services.</p><p>Our IT infrastructure features a <span>centralized data backup system</span> with scalable capacity and automated three-level data recovery procedures with hourly, daily, and weekly backups.</p> ',
    },
    {
      head: 'Global Digital<br />Security Standards',
      image: Cloud.src,
      desc: 'Such <span>IT infrastructure</span> aligns with the global digital security standards and complies with the <span>digital due diligence criteria</span> of various multinational organizations, and it is certified and audited by the security centers of global organizations in <span>UK, Switzerland, Denmark, Singapore,</span> and numerous other locations.',
    },
  ];
  return (
    <Wrapper>
      <div className='upper'>
        <div className='title' style={{backgroundImage: `url(${Hbg.src})`}}>
          <img src={Title.src} alt='' />
        </div>
      </div>
      <div className='lower'>
        {allData.map((data, index) => (
          <article key={index}>
            <div className='cardtop'>
              <div
                className='header'
                dangerouslySetInnerHTML={{__html: data.head}}
              />
              <div className='headerImg'>
                <img src={data.image} />
              </div>
            </div>
            <div className='cardbottom'>
              <div className='content'>
                <div dangerouslySetInnerHTML={{__html: data.desc}}></div>
              </div>
            </div>
          </article>
        ))}
      </div>
    </Wrapper>
  );
};

export default DataSecurity;
