import styled from "styled-components";

export const WrapperAboutExtend = styled.div`
  min-width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  .leftsec {
    background-size: auto 45vw;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-color: #af4012;
    position: relative;
    width: 65vw;
    height: 100vh;
    overflow: hidden;
    &__fire {
      z-index: 15;
      height: 90vh;
      top: 20vh;
      /* bottom: 0; */
      left: 2%;
      max-height: 60vw;
      @media (min-width: 1900px) {
        left: 9%;
      }
    }
    &__arrow {
      z-index: 0;
      height: 100vh;
      top: 0%;
      left: 50%;
      transform: translateX(-50%);
    }
    & > * {
      position: absolute;
    }
    &__text {
      color: white;
      font-family: coolvetica_rg;
      top: 35%;
      left: 60%;
      transform: translateX(-50%);
      max-width: max-content;
      display: block;
      position: relative;
      font-size: 3rem;
      line-height: 3rem;
      z-index: 10;
      @media (max-height: 700px) {
        top: 33%;
      }
      > * {
        display: block;
        position: relative;
      }
      &__first {
        font-size: 4rem;
      }
      &__second {
        font-size: 12rem;
        line-height: 8.5rem;
      }
      &__third {
        font-size: 4rem;
        margin-top: 0.5rem;
        line-height: 3rem;
      }
    }
  }
`;
