import styled from 'styled-components';

export const Slide = styled.div`
  /* display: inline-block; */
  width: ${(props) => (props.width ? props.width : '40vw')};
  /* width: 35vw; */
  z-index: ${(props) => props.index};
  /* max-width: 761px; */
  height: 100vh;
  &:hover {
    cursor: pointer;
  }
  /* @media (max-width: 768px) {
    width: 100vw;
  } */

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

export const Expand = styled.div`
  margin-left: ${(props) =>
    props.expand ? (props.width == '92vw' ? '-5vw' : '0') : '0'};
  height: 100vh;
  width: ${(props) =>
    props.expand ? props.width || 'calc( 60vw - 80px )' : '0'};
  height: 100vh;
  transition: width ease-in-out 450ms;
  left: ${(props) => (props.margin ? props.margin : null)};
  position: relative;
  z-index: 5;
  @media (max-width: 768px) {
    width: ${(props) => (props.expand ? '100vw' : '0')};
    display: ${(props) => (props.whyUs ? 'none !important' : 'block')};
  }
`;

export const OuterWrapper = styled.div`
  width: 100vh;
  height: 100vw;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-y: scroll;
  overflow-x: scroll;
  @media (max-width: 768px) {
    scroll-behavior: smooth;
    @supports (-webkit-touch-callout: none) {
      scroll-behavior: unset;
    }
  }
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  .close {
    position: fixed;
    top: 0;
    left: 35%;
    width: 50px;
    z-index: 9;
  }
  @media (max-width: 768px) {
    overflow-x: hidden;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
  position: absolute;
  top: 0;
  left: 0;
`;
