import styled from "styled-components";

export const WhyUsSideWrapper = styled.div`
  :hover {
    @media (min-width: 1025px) {
      img {
        transform: scale(1.2);
      }
      .whyUs__title {
        transform: scale(1.1) translateX(1rem);
      }
    }
  }
  & > a {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
  }
  * {
    padding: 0;
    margin: 0;
  }
  position: relative;
  overflow: hidden;
  background-color: #e84e1b;
  height: 100%;

  .whyUs {
    &__image {
      position: absolute;
      top: 10vh;
      right: 3vw;
      height: 67vh;
      z-index: 2;
      transition: transform ease-in-out 450ms;
      @media (max-width: 1550px) {
        top: 6vh;
      }
      @media (max-width: 1366px) {
        height: 50vh;
        top: 15vh;
      }
      @media (max-width: 1024px) {
        height: 60vh;
      }
      @media (max-width: 768px) {
        max-height: 73vw;
        right: 17vw;
      }
      @media (max-width: 500px) {
        max-height: 100vw;
        right: 10vw;
      }
      @media (max-width: 480px) and (max-height: 900px){
        max-height: 100vw !important;
      }
      @media (max-width: 414px) and (max-height: 900px) {
        max-height: 85vw;
      }
    }

    &__title {
      transition: transform ease-in-out 450ms;
      position: absolute;
      top: 34vh;
      left: 0px;
      font-family: 'coolvetica_rg';
      @media (max-width: 1550px) and (max-height: 650px) {
        transform: scale(0.9) translate(-5%, -10%);
      }
      @media (max-width: 768px) {
        transform: scale(1.9) translate(26%, 55%);
        top: 28vh;
      }
      @media (max-width: 500px) {
        top: calc(34vh - 60px);
      }
      @media (max-width: 480px) and (max-height: 667px) {
        top: 23vh;
      }
      color: #11112f;
      &--1 {
        font-size: 5vw;
        line-height: 0.5;
        color: #fff;
        @media (max-width: 768px) {
          margin-left: -4px;
        }
      }
      &--2 {
        font-size: 4vw;
        line-height: 1;
        @media (max-width: 768px) {
          margin-left: -4px;
          line-height: 1.1;
        }
      }
      &--3 {
        font-size: 13vw;
        line-height: 8vw;
        margin-left: -7px;
        @media (max-width: 768px) {
          line-height: 7vw;
        }
        @media (max-width: 500px) {
          margin-left: -2px;
        }
      }
      &--4 {
        font-size: 4vw;
        line-height: 1.5;
        @media (max-width: 768px) {
          line-height: 1.5;
          margin-left: -5px;
        }
      }
    }

    &__content {
      position: absolute;
      width: 76%;
      top: 80vh;
      left: 11%;
      z-index: 2;
      color: #ffffff;
      text-align: justify;

      &--title {
        font-family: poppinsBold;
        font-size: 1.875rem;
        line-height: 1;
        margin-bottom: 0.2rem;
        text-transform: uppercase;
        @media (max-width: 768px) {
          font-size: 2rem;
        }

        @media (max-width: 480px) {
          font-size: 1.2rem;
          line-height: 1.2;
        }
      }
      &--body {
        font-family: poppinsRegular;
        font-size: 1rem;
        @media (max-width: 1440px) {
          font-size: 0.8rem;
        }
        @media (max-width: 1280px) {
          font-size: 1.2rem;
        }
        @media (max-width: 768px) {
          font-size: 1.5rem;
        }
        @media (max-width: 480px) {
          font-size: 0.875rem;
        }
      }

      @media (max-width: 1680px) {
        width: 82%;
      }
      @media (max-width: 1550px) and (max-height: 722px) {
        top: 77vh;
      }
      @media (max-width: 1440px) {
        width: 81%;
      }
      @media (max-width: 1280px) {
        top: 77vh;
      }
      @media (max-width: 1024px) {
        top: 75vh;
        width: 86%;
        left: 7%;
      }
      @media (max-width: 768px) {
        top: 70vh;
        width: 80%;
        text-align: start;
      }
      @media (max-width: 480px) {
        top: 70vh;
        width: 80%;
        line-height: 1.2;
        top: calc(68vh - 60px);
      }
      @media (max-width: 480px) and (max-height: 926px) {
        top: calc(72vh - 60px);
      }
      @media (max-width: 480px) and (max-height: 800px) {
        top: calc(70vh - 60px);
      }
      @media (max-width: 480px) and (max-height: 667px) {
        top: calc(65vh - 60px);
      }
      @media (max-width: 375px) and (max-height: 667px) {
        top: calc(68vh - 60px);
      }
    }
  }

  .home__whyus--hand {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 3rem;
    z-index: 1;
    max-width: 40px;
    @media (max-width: 1024px) and (min-width: 769px) {
      width: 20px;
      bottom: 4rem;
    }
    @media (max-width: 768px) {
      bottom: 6.5rem;
    }
    @media (max-width: 480px) {
      width: 6%;
      bottom: 13.5vh;
    }
    @media (max-width: 480px) and (max-height: 667px) {
      bottom: 12.5vh;
      @supports (-webkit-touch-callout: none) {
        bottom: 15vh;
      }
    }
    @media (max-width: 375px) and (max-height: 667px) {
      bottom: 12.5vh;
    }
  }
`;
