import styled from 'styled-components';
import bg from '../../public/image/package/bg.webp';
export const AboutUsHome = styled.div`
  * {
    padding: 0;
    margin: 0;
  }
  position: relative;
  overflow: hidden;
  background-color: #11112f;
  height: 100%;

  .home__aboutus--ropes {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;
    @media (max-width: 1024px) {
      right: -45px;
    }
    @media (max-width: 500px) {
      right: -10vw;
      transform: scale(1.2);
      top: -7%;
    }
  }

  .home__aboutus--hand {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 3rem;
    z-index: 1;
    max-width: 40px;
    @media (max-width: 1024px) and (min-width: 769px) {
      width: 20px;
      bottom: 4rem;
    }
    @media (max-width: 768px) {
      bottom: 6.5rem;
    }
    @media (max-width: 480px) {
      width: 6%;
      bottom: 13.5vh;
    }
    @media (max-width: 480px) and (max-height: 667px) {
      bottom: 12.5vh;
      @supports (-webkit-touch-callout: none) {
        bottom: 15vh;
      }
    }
    @media (max-width: 375px) and (max-height: 667px) {
      bottom: 12.5vh;
    }
  }
  .main-title {
    transition: all ease-in-out 450ms;
    position: absolute;
    top: 34vh;
    left: 15%;
    font-family: coolvetica_rg;
    font-size: 16.888125rem;
    color: #e14e1b;
    line-height: 0.6;

    @media (max-width: 1690px) {
      font-size: 12.888125rem;
      top: 36vh;
      left: 25%;
    }
    @media (max-width: 1540px) and (max-height: 650px) {
      font-size: 12rem;
      left: 27%;
    }

    @media (max-width: 1440px) {
      left: 14%;
    }
    @media (max-width: 1280px) {
      font-size: 12.888125rem;
      left: 21%;
      top: 34vh;
    }
    @media (max-width: 1024px) {
      font-size: 15.888125rem;
      left: 6%;
      top: 37vh;
    }
    @media (max-width: 768px) {
      font-size: 19.5rem;
      left: 25%;
      top: 37vh;
    }
    @media (max-width: 480px) {
      font-size: 8.65625rem;
      top: 30vh;
      left: 18%;
    }
    @media (max-width: 480px) and (max-height: 667px) {
      top: 25vh;
      left: 10%;
    }
    @media (max-width: 375px) and (max-height: 667px) {
      top: 28vh;
    }
  }

  .home__aboutus--description {
    position: absolute;
    width: 76%;
    top: 80vh;
    left: 11%;
    z-index: 2;
    color: #ffffff;
    text-align: justify;
    @media (max-width: 1680px) {
      width: 82%;
    }
    @media (max-width: 1550px) and (max-height: 722px) {
      top: 77vh;
    }
    @media (max-width: 1440px) {
      width: 81%;
    }
    @media (max-width: 1280px) {
      top: 77vh;
    }
    @media (max-width: 1024px) {
      top: 75vh;
      width: 86%;
      left: 7%;
    }
    @media (max-width: 768px) {
      top: 70vh;
      width: 80%;
      text-align: start;
    }
    @media (max-width: 480px) {
      width: 80%;
      line-height: 1.2;
      top: calc(68vh - 60px);
    }
    @media (max-width: 480px) and (max-height: 926px) {
      top: calc(72vh - 60px);
    }
    @media (max-width: 480px) and (max-height: 800px) {
      top: calc(70vh - 60px);
    }
    @media (max-width: 480px) and (max-height: 667px) {
      top: calc(65vh - 60px);
    }
    @media (max-width: 375px) and (max-height: 667px) {
      top: calc(68vh - 60px);
    }
    .home__aboutus--sub-title {
      font-family: poppinsBold;
      font-size: 1.875rem;
      line-height: 1;
      margin-bottom: 0.2rem;
      @media (max-width: 768px) {
        font-size: 2rem;
      }
      @media (max-width: 480px) {
        font-size: 1.375rem;
        line-height: 1.2;
      }
    }
    .home__aboutus--content {
      font-family: poppinsRegular;
      font-size: 1rem;
      @media (max-width: 1440px) {
        font-size: 0.8rem;
      }
      @media (max-width: 1280px) {
        font-size: 1.2rem;
      }
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
      @media (max-width: 480px) {
        font-size: 0.875rem;
      }
    }
  }
  img {
    transition: transform ease-in-out 450ms;
  }
  :hover {
    @media (min-width: 1025px) {
      img {
        transform: scale(1.2);
      }
      .main-title {
        transform: scale(1.1);
      }
    }
  }
`;

export const AboutUsExpanded = styled.div`
  width: 100%;
  height: 100%;
  background-color: #0e0a1d;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    z-index: 9;
  }
  .ropes {
    position: absolute;
    width: 100%;
    object-fit: cover;
    object-position: right;
    height: 100%;
    top: 0;
  }
  .person {
    height: 92vh;
    position: absolute;
    left: 0;
    bottom: -1px;
    z-index: 1;
    @media (max-width: 480px) {
      left: 0px;
      right: 0;
      margin: auto;
      width: 95%;
      height: auto;
      top: 0vh;
    }
    @media (max-width: 414px) and (max-height: 900px) {
      top: 22vh;
    }
  }
`;

export const AboutUsContent = styled.div`
  color: #fff;
  position: absolute;
  top: 18vh;
  right: 18vh;
  z-index: 1;
  @media (max-width: 1680px) {
    right: 8%;
    top: 17vh;
  }
  @media (max-width: 1550px) {
    top: 8vh;
  }
  @media (max-width: 1440px) {
    right: 4%;
  }
  @media (max-width: 1280px) {
    right: 2%;
  }
  @media (max-width: 1024px) {
    top: 24vh;
  }
  @media (max-width: 768px) {
    top: 50vh;
    right: 10vw;
  }
  @media (max-width: 480px) {
    left: 0;
    right: 0;
    margin: auto;
    width: 75%;
    top: calc(65vh - 60px);
  }
  height: fit-content;
  p {
    margin: 0;
  }

  .home__aboutus--subtitle {
    font-family: poppinsBold;
    font-size: 1.5rem;
    padding-bottom: 1.2rem;
    padding-right: 1.2rem;
    border-bottom: 1px dashed #fff;
    @media (max-width: 1440px) {
      font-size: 1.3rem;
    }
    @media (max-width: 1280px) {
      font-size: 1.5rem;
    }
    @media (max-width: 1024px) {
      font-size: 1.7rem;
    }
  }

  .home__aboutus {
    display: flex;
    flex-direction: column;
    width: fit-content;
    @media (max-width: 480px) {
      width: 100%;
    }
    &--title {
      @media (max-width: 480px) {
        border-bottom: 2px solid #ffffff99;
        margin-bottom: 1rem;
        :last-child {
          border: none;
        }
      }
      &-bar {
        height: 5px;
        width: 100%;
        background-color: #e14e1b;
        display: block;
        position: relative;
        z-index: 9;
        margin-top: 2rem;
        @media (max-width: 1024px) {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
      &-text {
        line-height: 0.8;
        font-size: 3.94749375rem;
        font-family: coolvetica_rg;
        margin: 0;
        margin-bottom: 1rem;
        @media (max-width: 1440px) {
          font-size: 2.74749375rem;
        }
        @media (max-width: 1024px) {
          font-size: 4rem;
        }
        @media (max-width: 480px) {
          font-size: 1.625rem;
        }
      }
    }
    .home__aboutus--title-bar {
      @media (max-width: 480px) {
        display: none !important;
      }
      @media (max-width: 480px) {
        width: 100%;
        height: 0.1px;
        background-color: #ffffff;
        display: block;
        position: relative;
        z-index: 9;
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }
  .home__aboutus--button {
    position: relative;
    top: 7vh;
    /* left: 18%; */
    z-index: 1;
    cursor: pointer;
    &-showmore {
      background-color: #e34e1b;
      color: #fff;
      border-radius: 10px;
      padding: 10px 20px;
      border: none;
      font-family: poppinsBold;
      font-size: 1rem;
      width: fit-content;
      margin: 0;
      cursor: pointer;
      @media (max-width: 480px) {
        font-size: 0.6rem;
      }
    }
    @media (max-height: 800px) {
      top: 3vh;
    }
    @media (max-width: 768px) {
      top: 3vh;
    }
    @media (max-width: 480px) {
      top: 1vh;
    }
  }
`;

export const OurPackages = styled.div`
  * {
    padding: 0;
    margin: 0;
  }
  position: relative;
  overflow: hidden;
  background-color: #fdf48e;
  height: 100%;

  .home__package--balloon {
    position: absolute;
    max-width: 50%;
    top: 45%;
    right: 10%;
    z-index: 1;
    transform: translateY(-50%);
    @media (max-height: 750px) {
      max-width: 50%;
    }
    @media (max-width: 1550px) {
      top: 42%;
      max-width: 42%;
    }
    @media (max-width: 1024px) {
      max-width: 60%;
      top: 38%;
    }

    @media (max-width: 768px) {
      top: 40vh;
      max-width: 40%;
    }
    @media (max-width: 500px) {
      top: 35vh;
      max-width: 45%;
    }
  }
  .home__package--hand {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 3rem;
    z-index: 1;
    max-width: 40px;
    @media (max-width: 1024px) and (min-width: 769px) {
      width: 20px;
      bottom: 4rem;
    }
    @media (max-width: 768px) {
      bottom: 6.5rem;
    }
    @media (max-width: 480px) {
      width: 6%;
      bottom: 13.5vh;
    }
    @media (max-width: 480px) and (max-height: 667px) {
      bottom: 12.5vh;
      @supports (-webkit-touch-callout: none) {
        bottom: 15vh;
      }
    }
    @media (max-width: 375px) and (max-height: 667px) {
      bottom: 12.5vh;
    }
  }
  .main-title {
    transition: all ease-in-out 450ms;
    position: absolute;
    top: 34vh;
    left: -2%;
    font-family: coolvetica_rg;
    font-size: 13rem;
    color: #00968f;
    line-height: 0.6;

    @media (max-width: 1690px) {
      font-size: 11.5rem;
      top: 36vh;
    }
    @media (max-width: 1550px) {
      font-size: 10.2rem;
      left: -1%;
    }

    @media (max-width: 1280px) {
      font-size: 12rem;
      top: 34vh;
    }
    @media (max-width: 1024px) {
      font-size: 13rem;
      top: 37vh;
    }
    @media (max-width: 768px) {
      font-size: 27vw;
      top: 35vh;
      left: -1%;
    }
    @media (max-width: 500px) {
      font-size: 27vw;
      left: -3%;
      top: 30vh;
    }
    @media (max-width: 480px) and (max-height: 667px) {
      top: 25vh;
    }
    @media (max-width: 375px) and (max-height: 667px) {
      top: 28vh;
    }
  }

  .home__package--description {
    position: absolute;
    width: 78%;
    top: 80vh;
    left: 11%;
    z-index: 2;
    color: #00968f;
    text-align: justify;
    @media (max-width: 1680px) {
      width: 82%;
    }
    @media (max-width: 1550px) and (max-height: 722px) {
      top: 77vh;
    }
    @media (max-width: 1440px) {
      width: 81%;
    }
    @media (max-width: 1280px) {
      top: 77vh;
    }
    @media (max-width: 1024px) {
      top: 75vh;
      width: 86%;
      left: 7%;
    }
    @media (max-width: 768px) {
      top: 70vh;
      width: 80%;
      text-align: start;
    }
    @media (max-width: 480px) {
      width: 82%;
      line-height: 1.2;
      top: calc(68vh - 60px);
    }
    @media (max-width: 480px) and (max-height: 926px) {
      top: calc(72vh - 60px);
    }
    @media (max-width: 480px) and (max-height: 800px) {
      top: calc(70vh - 60px);
    }
    @media (max-width: 480px) and (max-height: 667px) {
      top: calc(65vh - 60px);
    }
    @media (max-width: 375px) and (max-height: 667px) {
      top: calc(68vh - 60px);
    }
    .home__package--sub-title {
      font-family: poppinsBold;
      font-size: 1.875rem;
      line-height: 1;
      margin-bottom: 0.2rem;
      text-transform: uppercase;
      text-align: start;
      @media (max-width: 1024px) {
        width: 108%;
      }
      @media (max-width: 768px) {
        font-size: 2rem;
        width: 100%;
      }
      @media (max-width: 480px) {
        font-size: 1.375rem;
        line-height: 1.2;
      }
    }

    .home__package--content {
      font-family: poppinsRegular;
      font-size: 1rem;
      color: #09756f;
      @media (max-width: 1440px) {
        font-size: 0.8rem;
      }
      @media (max-width: 1280px) {
        font-size: 1.2rem;
      }
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
      @media (max-width: 480px) {
        font-size: 0.875rem;
      }
    }
  }

  img {
    transition: transform ease-in-out 450ms;
  }
  :hover {
    @media (min-width: 1025px) {
      img {
        transform: scale(1.05) translateY(-50%);
      }
      .main-title {
        transform: scale(1.02);
      }
    }
  }
`;

export const PackagesExpanded = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f0c981;
  background-image: url(${bg.src});
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    z-index: 9;
  }
  .lamb {
    position: absolute;
    width: 100%;
    object-fit: cover;
    object-position: right;
    /* height: 100%; */
    top: 0;
    left: 0;
    height: 100%;

    @media (max-width: 1024px) {
      width: unset;
      top: 0;
      left: -30%;
      height: 100%;
    }
    @media (max-width: 768px) {
      width: 65%;
      top: 0;
      left: 0;
      height: unset;
    }
    @media (max-width: 500px) {
      width: 80%;
      top: -2vh;
      left: 0;
      height: unset;
    }
    @media (max-width: 414px) and (max-height: 900px) {
      width: 70%;
    }
  }
  .person {
    height: 92vh;
    position: absolute;
    left: 0;
    bottom: -1px;
    z-index: 1;
    @media (max-width: 480px) {
      left: 0px;
      right: 0;
      margin: auto;
      width: 95%;
      height: auto;
      top: 0vh;
    }
  }
`;

export const PackagesContent = styled.div`
  color: #004843;
  position: absolute;
  top: 10vh;
  right: 14vw;
  z-index: 1;

  @media (max-width: 1680px) {
    transform: scale(0.8) translateY(-15%);
  }
  @media (max-width: 1024px) {
    transform: scale(1) translateY(20%);
  }

  @media (max-width: 768px) {
    top: unset;
    right: unset;
    left: 55%;
    bottom: 15vh;
    transform: scale(0.9);
  }
  @media (max-width: 500px) {
    right: unset;
    top: unset;
    bottom: 5vh;
    left: -5%;
    transform: scale(0.45) translateY(20%);
  }
  @media (max-width: 375px) and (max-height: 667px) {
    bottom: 0;
  }

  height: fit-content;
  p {
    margin: 0;
  }
  .home__package--subtitle {
    font-family: poppinsBold;
    font-size: 1.5rem;
    padding-bottom: 1.2rem;
    padding-right: 1.2rem;
    border-bottom: 1px dashed #004843;
    @media (max-width: 1440px) {
      font-size: 1.3rem;
    }
    @media (max-width: 1280px) {
      font-size: 1.5rem;
    }
    @media (max-width: 1024px) {
      font-size: 1.7rem;
    }
  }
  .home__package {
    display: flex;
    flex-direction: column;
    width: fit-content;
    @media (max-width: 480px) {
      width: 100%;
    }
    &--title {
      &-bar {
        height: 1px;
        width: 150%;
        background-color: #004843;
        display: block;
        position: relative;
        z-index: 9;
        margin-bottom: 0.5rem;
      }
      &-text {
        line-height: 0.8;
        font-size: 3rem;
        font-family: coolvetica_rg;
        margin-bottom: 0;
      }
      &-small {
        font-family: 'poppinsBold';
        font-size: 1.5rem;
        text-transform: uppercase;
        line-height: 3rem;
      }
    }
  }
  .home__package--button {
    position: relative;
    /* left: 18%; */
    z-index: 1;
    cursor: pointer;
    &-showmore {
      background-color: #00968f;
      color: #fff;
      border-radius: 10px;
      padding: 10px 20px;
      border: none;
      font-family: poppinsBold;
      font-size: 1rem;
      width: fit-content;
      margin: 0;
      cursor: pointer;
      @media (max-width: 480px) {
        font-size: 1.5rem;
        padding: 15px 30px;
      }
    }
    @media (max-width: 1680px) {
      top: 2vh;
    }
    @media (max-height: 800px) {
      top: 3vh;
    }
    @media (max-width: 480px) {
      top: 1vh;
    }
  }
`;
