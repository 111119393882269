import styled from "styled-components";
import Rectangle_1 from "../../../public/image/WhyUs/Organic/rectangle-1.webp";
import Rectangle_2 from "../../../public/image/WhyUs/Organic/rectangle-2.webp";
import Rectangle_3 from "../../../public/image/WhyUs/Organic/rectangle-3.webp";
import Rectangle_4 from "../../../public/image/WhyUs/Organic/rectangle-4.webp";

export const Wrapper = styled.div`
  background-repeat: no-repeat;
  background-size: 80vw;
  background-position: 10vw -5vh;
  min-height: 100vh;
  padding-top: 7vh;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;

  position: relative;
  @media (max-width: 1460px) {
    padding-top: 1rem;
  }
  @media (max-width: 1280px) {
    padding-top: 1rem;
  }

  @media (max-height: 740px) {
    font-size: 0.7rem;
    padding-top: 1rem;
  }

  .OrganicScalability__man {
    position: absolute;
    right: -2rem;
    width: 35%;
    max-width: 64.5vh;
    bottom: 0;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;

    @media (max-width: 1460px) {
      right: -2rem;
    }
    @media (max-width: 1024px) {
     width : 30%;
    }
    img {
      width: auto;
      max-height: 100vh;
      max-width: 100%;
    }
  }

  .OrganicScalability__upper {
    &__title {
      text-align: center;
      margin-bottom: 2vh;
      img {
        max-width: 10vw;
        @media (max-height: 700px) {
          height: 100px;
        }

        /* animation-name: organic;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        transform-origin: top; */
      }
    }
  }

  .OrganicScalability {
    &__lower {
      /* padding: 2vh 0 0 10vw; */
      padding-left: 10vw;
      display: flex;
      flex-direction: column;
      @media (max-width: 1024px) {
        line-height: 1;
      }
      &__section {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        &__upper {
          padding-top: 4rem;
          @media (max-height: 800px) {
            padding-top: 2rem;
          }
        }
      }

      &__left {
        background-color: transparent;
        max-width: 35%;
      }
      &__right {
        background-color: rgba(0, 149, 141, 0.75);
        width: 100%;
        &__upper {
          box-shadow: 0 -5rem 0 rgba(0, 149, 141, 0.75);
          @media (max-height: 800px) {
            box-shadow: 0 -2rem 0 rgba(0, 149, 141, 0.75);
          }

          img {
            margin-right: 4rem;
            @media (max-height: 700px) {
              margin-right: 2rem;
            }
          }
        }
        &__down {
          height: 100vh;
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      color: #fff;
      height: 4.5rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      font-family: poppinsBold;
      font-size: 1.2rem;
      img {
        max-width: 100%;
        max-height: 6vw;
      }
      @media (max-height: 1440px) {
        font-size: 1.1rem;
      }
      
      @media (max-height: 700px) {
        font-size: 1rem;
        height: 4rem;
        img {
          height: 80px;
        }
      }

      @media (max-height: 600px) {
        font-size: 1rem;
        height: 3rem;
        /* img {
          height: 60px;
        } */
      }

      @media (max-height: 768px) and (max-width: 1024px) {
        font-size: 1.2rem;
        img {
          height: 50px;
        }
      }

      &__left {
        flex-direction: row-reverse;
        padding: 0 1.5rem 0 4rem;
        justify-content: space-between;
        text-align: right;
      }

      &__right {
        width: 60%;
        padding: 0 15% 0 1.5rem;
        justify-content: space-between;
        @media (max-height: 768px) and (max-width: 1024px) {
          padding: 0 22% 0 1.5rem;
        }
      }

      &__rectangle-1 {
        background-image: url(${Rectangle_1.src});
      }
      &__rectangle-2 {
        background-image: url(${Rectangle_2.src});
      }
      &__rectangle-3 {
        background-image: url(${Rectangle_3.src});
      }
      &__rectangle-4 {
        background-image: url(${Rectangle_4.src});
      }
    }

    &__content {
      font-size: 0.9rem;
      font-family: poppinsRegular;
      text-align: justify;

      @media (max-width: 1680px) {
        font-size: 1rem;
      }
      @media (max-height: 800px) {
        font-size: 0.9rem;
      }
      @media (max-height: 750px) {
        font-size: 0.85rem;
      }
      @media (max-height: 650px) and (max-width: 1460px) {
        font-size: 0.75rem;
      }
      @media (max-height: 600px) {
        /* font-size: 0.8rem; */
      }
      @media (max-height: 768px) and (max-width: 1024px) {
        font-size: 1.35rem;
      }
      &__left {
        color: #3c3c3b;
        padding: 1rem 1.5rem 1rem 0;
        @media (max-width: 1024px) {
          padding: 0 1rem 0 0;
        }
        @media (max-height: 700px) {
          /* padding-top: 0.25rem;
          padding-bottom: 0.5rem; */
        }
      }

      &__right {
        color: #fff;
        width: 50%;
        padding: 1rem 4rem 1rem 1.5rem;
        @media (max-width: 1024px) {
          padding: 0 0rem 0 1rem;
        }
        @media (max-height: 700px) {
          /* padding-top: 0.25rem;
          padding-bottom: 0.5rem; */
        }

        /* @media (max-height: 768px) and (max-width: 1024px) {
          width: 40%;
        } */
      }

      &__colored {
        color: #11112f;
        font-family: poppinsBold;
        font-size: 0.95rem;
      }
      &__not-colored {
        font-family: poppinsBold;
        &--bold {
          text-transform: uppercase;
          font-size: 0.85rem;
        }
      }
    }
  }
`;
