import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { Expand, Slide } from "../../../styles/Home.style";
import { useSelector, useDispatch } from "react-redux";
import { selectActiveSectionIndex, selectWhyActiveSectionIndex, setActiveSection, setWhyActive } from "../../../core/state/navigation.slice";
import HRManagement from "../../whyUs/HRManagement/HRManagement";
import OperationExcellence from "../../whyUs/OperationExcellence/OperationExcellence";
import OrganicScalability from "../../whyUs/OrganicScalability/OrganicScalability";
import DataSecurity from "../../whyUs/DataSecurity/DataSecurity";
import WhuUsSide from "./WhuUsSide/WhuUsSide";
import WhyUsExpand from "./WhyUsExpand/WhyUsExpand";
import WhyUsButton from "./WhyUsButton/WhyUsButton";

import bt1 from "../../../public/image/whyUs/tab-1.webp";
import bt2 from "../../../public/image/whyUs/tab-2.webp";
import bt3 from "../../../public/image/whyUs/tab-3.webp";
import bt4 from "../../../public/image/whyUs/tab-4.webp";
import bt5 from "../../../public/image/whyUs/tab-5.webp";
import OperationIntelligence from "../../whyUs/OperationIntelligence/OperationIntelligence";

const WhyUs = () => {
  const activeSection = useSelector(selectActiveSectionIndex);
  const whyActiveSection = useSelector(selectWhyActiveSectionIndex);
  const windowWidth = useSelector((state) => state.windowWidth.windowWidth);

  const dispatch = useDispatch();
  const router = useRouter();
  return (
    <>
      {/* the first slide with 30vw */}
      <Slide
        onClick={() => {
          if (windowWidth == "desktop") {
            if (activeSection === 2) {
              dispatch(setActiveSection(-1));
              dispatch(setWhyActive(-1));
            } else {
              dispatch(setActiveSection(2));
            }
          } else {
            dispatch(setActiveSection(-1));
            router.push("/whyUs");
          }
        }}
      >
        {/* side menu */}
        <WhuUsSide checkWidth={windowWidth} />
        {/* end side menu */}
      </Slide>

      {/* the main body of show more with 50vw */}
      <Expand expand={activeSection === 2} whyUs={true} style={activeSection === 2 ? { display: "flex" } : null}>
        <WhyUsExpand />
      </Expand>
      {/* the reset of the section */}

      {/* start of OrganicScalability */}
      <WhyUsButton
        title="Organic Scalability"
        background={bt1.src}
        setColor="#b84616"
        setReverseColor="#722e0a"
        close={whyActiveSection === 1 ? true : false}
        onClick={() => {
          if (whyActiveSection === 1) {
            dispatch(setWhyActive(-1));
          } else {
            dispatch(setWhyActive(1));
          }
        }}
        setWidth={activeSection === 2}
        index={50}
      />
      <Expand expand={whyActiveSection === 1} width="92vw" whyUs={true}>
        <OrganicScalability />
      </Expand>

      {/* end of OrganicScalability */}

      {/* start of Operational Excellence */}
      <WhyUsButton
        title="Operational Excellence"
        background={bt2.src}
        setColor="#722e0a"
        setReverseColor="#b84616"
        close={whyActiveSection === 2 ? true : false}
        onClick={() => {
          if (whyActiveSection === 2) {
            dispatch(setWhyActive(-1));
          } else {
            dispatch(setWhyActive(2));
          }
        }}
        setWidth={activeSection === 2}
        index={40}
      />
      <Expand expand={whyActiveSection === 2} width="92vw" whyUs={true}>
        <OperationExcellence />
      </Expand>

      {/* end of Operational Excellence */}

      {/* start of Operational Intelligence */}
      <WhyUsButton
        title="Operational Intelligence"
        background={bt3.src}
        setColor="#b84616"
        setReverseColor="#722e0a"
        close={whyActiveSection === 3 ? true : false}
        onClick={() => {
          if (whyActiveSection === 3) {
            dispatch(setWhyActive(-1));
          } else {
            dispatch(setWhyActive(3));
          }
        }}
        setWidth={activeSection === 2}
        index={30}
      />
      <Expand expand={whyActiveSection === 3} width="92vw" whyUs={true}>
        <OperationIntelligence />
        {/* <NewOperation /> */}
      </Expand>

      {/* end of Operational Intelligence */}

      {/* start of HR Management */}
      <WhyUsButton
        title="Panoramic HR Management"
        background={bt4.src}
        setColor="#722e0a"
        setReverseColor="#b84616"
        close={whyActiveSection === 4 ? true : false}
        onClick={() => {
          if (whyActiveSection === 4) {
            dispatch(setWhyActive(-1));
          } else {
            dispatch(setWhyActive(4));
          }
        }}
        setWidth={activeSection === 2}
        index={20}
      />
      <Expand expand={whyActiveSection === 4} width="92vw" whyUs={true}>
        <HRManagement />
      </Expand>

      {/* end of HR Management */}

      {/* start of Data Security */}
      <WhyUsButton
        title="Optimized Data Security"
        background={bt5.src}
        setColor="#722e0a"
        setReverseColor="#722e0a"
        close={whyActiveSection === 5 ? true : false}
        onClick={() => {
          if (whyActiveSection === 5) {
            dispatch(setWhyActive(-1));
          } else {
            dispatch(setWhyActive(5));
          }
        }}
        setWidth={activeSection === 2}
        index={10}
      />
      <Expand expand={whyActiveSection === 5} width="92vw" whyUs={true}>
        <DataSecurity />
      </Expand>

      {/* end of Data Security */}
    </>
  );
};

export default WhyUs;
