import styled from 'styled-components';

export const Wrapper = styled.div`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  width: calc(100vw - 80px);
  height: 100vh;
  display: grid;
  grid-template-columns: 40vw calc(60vw - 80px);
  @media (max-width: 768px) {
    width: 200vw;
    display: grid;
    grid-template-columns: 100vw 100vw;
  }

  .contactUs {
    &--left {
      z-index: 1;
      position: relative;
      height: 100%;
      background-color: #4a3068;
      overflow: hidden;
      h2 {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14.5rem;
        font-family: coolvetica_rg;

        line-height: 0.75;
        color: #fdf48e;
        font-weight: 400;
        @media (max-width: 1690px) {
          font-size: 12.888125rem;
        }

        @media (max-width: 1550px) {
          font-size: 11.5rem;
        }

        @media (max-width: 1440px) {
          font-size: 11rem;
        }
        @media (max-width: 1330px) {
          font-size: 10.5rem;
        }

        @media (max-width: 1280px) {
          font-size: 11.888125rem;
        }

        @media (max-width: 1024px) {
          font-size: 12.5rem;
        }
        @media (max-width: 768px) {
          font-size: 19rem;
        }
        @media (max-width: 480px) {
          top: 37%;
          font-size: 8rem;
        }
        @media (max-width: 400px) {
          font-size: 7.5rem;
        }
        @media (max-width: 350px) {
          font-size: 6.5rem;
        }
      }
      img {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 65vh;
        @media (max-width: 1550px) {
          top: 43%;
        }
        @media (max-width: 1024px) {
          top: 40%;
        }
        @media (max-width: 768px) {
          top: 43%;
          height: 50vh;
        }
        @media (max-width: 480px) {
          top: 37%;
          height: 45vh;
        }
        @media (max-width: 480px) and (max-height: 667px) {
          width: 45%;
          height: 40vh;
          top: 35%;
        }
      }
      &__content {
        position: absolute;
        width: 76%;
        top: 80vh;
        left: 11%;
        z-index: 2;
        color: #ffffff;
        text-align: justify;
        @media (max-width: 1680px) {
          width: 82%;
        }
        @media (max-width: 1550px) {
          top: 77vh;
        }
        @media (max-width: 1440px) {
          width: 81%;
        }
        @media (max-width: 1280px) {
          top: 77vh;
        }
        @media (max-width: 1024px) {
          top: 75vh;
          width: 86%;
          left: 7%;
        }
        @media (max-width: 768px) {
          top: 70vh;
          width: 80%;
          text-align: start;
        }
        @media (max-width: 480px) {
          width: 80%;
          line-height: 0.9;
        }
        @media (max-width: 480px) and (max-height: 926px) {
          top: calc(72vh - 60px);
        }
        @media (max-width: 480px) and (max-height: 800px) {
          top: calc(70vh - 60px);
        }
        @media (max-width: 480px) and (max-height: 667px) {
          top: calc(65vh - 60px);
        }
        @media (max-width: 375px) and (max-height: 667px) {
          top: calc(68vh - 60px);
        }
        h4 {
          font-family: poppinsBold;
          font-size: 1.875rem;
          line-height: 1;
          margin-bottom: 0.2rem;
          text-transform: uppercase;
          @media (max-width: 768px) {
            font-size: 2rem;
          }
          @media (max-width: 480px) {
            font-size: 1.375rem;
            line-height: 1.2;
          }
        }
        p {
          font-family: poppinsRegular;
          font-size: 1rem;
          @media (max-width: 1440px) {
            font-size: 0.8rem;
          }
          @media (max-width: 1280px) {
            font-size: 1.2rem;
          }
          @media (max-width: 768px) {
            font-size: 1.5rem;
          }
          @media (max-width: 480px) {
            font-size: 0.875rem;
            line-height: 1.2;
          }
        }
        &-pc {
          @media (max-width: 480px) {
            display: none;
          }
        }
        &-mobile {
          display: none;
          @media (max-width: 480px) {
            display: block;
            line-height: 1 !important;
          }
        }
      }
    }
    &--right {
      z-index: 2;
      position: relative;
      height: 100%;
      background-color: #3b2552;
      overflow: hidden;
      &__image-background {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 0;
        height: 94vh;
        filter: hue-rotate(216deg) opacity(0.5) grayscale(1);
        @media (max-width: 480px) {
          height: 100%;
        }
      }
      &__image-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        @media (max-width: 1024px) {
          display: none;
        }
      }
      &--content {
        z-index: 2;
        top: 15vh;
        margin: 0 5vh;
        position: absolute;
        line-height: 1;
        @media (max-width: 1680px) {
          top: 6vh;
        }
        @media (max-width: 1536px) {
          top: 3vh;
        }
        @media (max-width: 1440px) and (max-height: 750px) {
          top: 11vh;
        }
        @media (max-width: 1441px) and (max-height: 722px) {
          top: 6vh;
        }
        @media (max-width: 1280px) {
          top: 7vh;
        }
        @media (max-width: 768px) {
          top: calc(80px + 3rem);
        }
        @media (max-width: 480px) {
          top: calc(80px + 1rem);
        }
        h3 {
          font-family: coolvetica_rg;
          font-size: 3.188025rem;
          color: #fff;
          font-weight: 400;
          @media (max-width: 1440px) and (max-height: 750px) {
            font-size: 2.5rem;
          }
          @media (max-width: 1025px) {
            padding-bottom: 0.5rem;
          }
          @media (max-width: 768px) {
            font-size: 3.6rem;
          }
          @media (max-width: 480px) {
            font-size: 1.6rem;
            line-height: 0.9;
          }
          @media (max-width: 480px) and (max-height: 760px) {
            font-size: 1.3rem;
            line-height: 0.8;
          }
          @media (max-width: 480px) and (max-height: 700px) {
            font-size: 1.1rem;
            line-height: 0.8;
          }
        }
        h4 {
          color: #fdf48e;
          font-family: poppinsMedium;
          font-size: 1.3064625rem;
          font-weight: 400;
          line-height: 0.8;
          @media (max-width: 1440px) and (max-height: 750px) {
            font-size: 1rem;
          }
          @media (max-width: 768px) {
            font-size: 1.6rem;
          }
          @media (max-width: 480px) {
            font-size: 0.8125rem;
            line-height: 1;
          }
          @media (max-width: 480px) and (max-height: 760px) {
            font-size: 0.79rem;
            line-height: 0.9;
          }
          @media (max-width: 480px) and (max-height: 700px) {
            font-size: 0.75rem;
            line-height: 0.8;
          }
        }
        h5 {
          color: #fff;
          font-family: poppinsRegular;
          font-size: 1.10823125rem;
          margin: 2.75rem 0 2.5rem;
          font-weight: 400;
          @media (max-width: 1680px) and (max-height: 800px) {
            margin: 1.75rem 0 1.5rem;
          }
          @media (max-width: 1440px) and (max-height: 750px) {
            margin: 1.5rem 0 1.5rem;
          }
          @media (max-width: 1024px) {
            padding-top: 2.5rem;
            padding-bottom: 1rem;
          }
          @media (max-width: 768px) {
            font-size: 1.25rem;
            margin: 1.25rem 0 1.25rem;
            text-align: justify;
          }
          @media (max-width: 480px) {
            font-size: 0.9rem;
            margin: 0.6rem 0;
            padding: 0;
            text-align: start;
          }
          @media (max-width: 480px) and (max-height: 760px) {
            margin: 0.3rem 0;
            font-size: 0.8rem;
          }
          @media (max-width: 480px) and (max-height: 700px) {
            margin: 0.2rem 0;
            font-size: 0.75rem;
          }
        }
        form {
          display: grid;
          gap: 0.5rem 2rem;
          grid-template-columns: 1fr 1fr;
          border-bottom: 1px solid #867596;
          padding-bottom: 0.5rem;
          @media (max-height: 750px) {
            gap: 0.5rem 2rem;
          }
          @media (max-width: 1024px) {
            gap: 2.5rem 2rem;
          }
          @media (max-width: 768px) {
            gap: 2rem 2rem;
          }
          @media (max-width: 480px) {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
          }
          @media (max-width: 480px) and (max-height: 760px) {
            gap: 0.15rem;
          }
          @media (max-width: 480px) and (max-height: 700px) {
            gap: 0;
          }

          .form__error {
            border-bottom: 1px solid #bd2020;
            color: #bd2020;
            ::placeholder {
              color: #bd2020;
            }
          }
          small {
            color: #bd2020;
            font-family: poppinsRegular;
            min-height: 1rem;
            min-width: 1rem;
            @media (max-width: 768px) {
              font-size: 1.25rem;
            }
            @media (max-width: 480px) {
              font-size: 0.9rem;
            }
            @media (max-width: 480px) and (max-height: 760px) {
              font-size: 0.75rem;
            }
            @media (max-width: 480px) and (max-height: 700px) {
              font-size: 0.7rem;
            }
          }
          textarea {
            resize: none;
            ::-webkit-scrollbar {
              width: 5px;
              display: block;
              cursor: move;
            }

            ::-webkit-scrollbar-track {
              background: transparent;
            }

            ::-webkit-scrollbar-thumb {
              background: #fdf48e;
            }

            ::-webkit-scrollbar-thumb:hover {
              background: #ffffff;
            }
          }
          input,
          textarea {
            border: none;
            border-bottom: 1px solid #867596;
            font-family: poppinsRegular;
            font-size: 1.0625rem;
            background-color: transparent;
            width: 100%;
            padding: 0.7rem 0 0.5rem;
            color: #fff;
            @media (max-height: 750px) {
              padding: 0.25rem 0 0.15rem;
            }
            @media (max-width: 768px) {
              font-size: 1.75rem;
              padding: 0;
              padding-bottom: 0.25rem;
            }
            @media (max-width: 480px) {
              font-size: 0.9rem;
            }
            @media (max-width: 480px) and (max-height: 760px) {
              font-size: 0.85rem;
            }
            @media (max-width: 480px) and (max-height: 700px) {
              font-size: 0.75rem;
            }
            :focus-visible {
              outline: none;
            }
            ::placeholder {
              color: #a796b4;
            }
          }
          button {
            background-color: #fdf48e;
            border: none;
            color: #3b2552;
            font-family: poppinsBold;
            font-size: 1rem;
            border-radius: 0.5rem;
            padding: 0.65rem 0.5rem;
            width: 6.083375rem;
            margin-top: 1rem;
            cursor: pointer;
            @media (max-width: 1440px) and (max-height: 750px) {
              margin-top: 0.5rem;
            }
            @media (max-height: 750px) {
              margin-top: 0.5rem;
            }
            @media (max-width: 768px) {
              font-size: 1.5rem;
              grid-column: 1 / 3;
              width: auto;
              padding: 1rem 0.75rem;
            }
            @media (max-width: 480px) {
              margin-top: 0;
              font-size: 1rem;
              padding: 0.5rem 0.75rem;
            }
            @media (max-width: 480px) and (max-height: 760px) {
              font-size: 0.9rem;
              padding: 0.5rem 0.75rem;
            }
            @media (max-width: 480px) and (max-height: 700px) {
              margin-top: 0.25rem;
              font-size: 0.85rem;
              padding: 0.35rem 0.75rem;
            }
          }
        }
        &--full-grid {
          grid-column: 1 / 3;
        }

        &__footer {
          h5 {
            font-size: 0.93843125rem;
            color: #fdf48e;
            text-transform: uppercase;
            font-family: poppinsBold;
            font-weight: 400;
            margin: 1.25rem 0 1.5rem;
            @media (max-height: 750px) {
              margin-top: 0.75rem;
              margin-bottom: 0.75rem;
            }
            @media (max-width: 768px) {
              margin-top: 0.05rem;
              margin-bottom: 0.05rem;
              font-size: 1.5rem;
            }
            @media (max-width: 480px) {
              margin-bottom: 0;
              font-size: 0.8rem;
            }
            @media (max-width: 480px) and (max-height: 700px) {
              margin-top: 0.25rem;
              margin-bottom: 0;
              font-size: 0.75rem;
            }
          }
          &--office {
            border-bottom: 0.25px solid rgba(134, 117, 150, 0.5);
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            font-family: poppinsBold;
            font-weight: 400;
            font-size: 0.77235rem;
            color: #fff;
            padding-bottom: 0.15rem;
            @media (max-width: 768px) {
              font-size: 1rem;
            }
            @media (max-width: 480px) {
              font-size: 0.8rem;
            }
          }
          &--address {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            font-family: poppinsRegular;
            font-weight: 400;
            font-size: 0.77235rem;
            color: #fff;
            margin-top: 0.35rem;
            @media (max-width: 768px) {
              font-size: 1rem;
            }
            @media (max-width: 480px) {
              font-size: 0.7rem;
            }
          }
          &--mobile {
            &__address {
              color: #fff;
              font-size: 0.62055rem;
              font-family: poppinsRegular;
              &--bold {
                display: block;
                font-family: poppinsBold;
                border-bottom: thin solid #867596;
                padding: 0.2rem 0;
                margin-bottom: 0.2rem;
                @media (max-width: 480px) and (max-height: 700px) {
                  margin-bottom: 0.1rem;
                  padding: 0.1rem 0;
                }
              }
              @media (max-width: 480px) and (max-height: 700px) {
                font-size: 0.6rem;
              }
            }
          }
          &--contact {
            margin-top: 1rem;
            @media (max-width: 480px) {
              margin-top: 0.3rem;
            }
          }
          &--contact--info {
            font-family: poppinsBold;
            font-size: 0.77235rem;
            color: #fff;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 0.35rem;
            img {
              height: 20px;
            }
            @media (max-width: 768px) {
              font-size: 1.25rem;
            }
            @media (max-width: 480px) {
              font-size: 0.62055rem;
            }

            &--colored {
              color: #fdf48e;
            }
          }
        }
      }
    }
  }

  .book__now {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    width: 20rem;
    @media (max-width: 768px) {
      margin-top: 0.5rem;
    }
    @media (max-width: 480px) {
      margin-top: 0.3rem;
    }
    button {
      width: fit-content;
      padding-inline: 1rem;
      background-color: #fff;
      border: none;
      color: #3b2552;
      font-family: poppinsBold;
      border-radius: 0.3rem;
      padding: 0.3rem 0.5rem;
      margin-top: 0.5rem;
      cursor: pointer;
      @media (max-width: 768px) {
        padding: 0.2rem 0.3rem;
      }
    }
  }
`;

export const SuccessMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255, 0.6);
  animation: enter 450ms ease-in-out forwards;
  visibility: hidden;
  z-index: 999;

  @keyframes enter {
    from {
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
      visibility: visible;
    }
  }

  div {
    text-align: center;
    padding: 1rem;
    border-radius: 10px;
    background-color: #11112f;
    color: #fff;
    font-family: poppinsRegular;
    width: 80%;
    max-width: 300px;
    position: relative;
    z-index: 999;
    img {
      display: block;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 0.5rem;
    }
  }
`;
