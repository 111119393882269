import React from 'react';
import {useRouter} from 'next/router';
import {Expand, Slide} from '../../styles/Home.style';
import balloon from '../../public/image/package/balloon.webp';

import lamb from '../../public/image/package/lamb.webp';
import mobileLamb from '../../public/image/package/mobileLamb.webp';
import scroll_hand from '../../public/image/package/scroll_hand.png';
import {OurPackages, PackagesContent, PackagesExpanded} from './Home.style';
import {useSelector, useDispatch} from 'react-redux';
import {
  selectActiveSectionIndex,
  setActiveSection,
} from '../../core/state/navigation.slice';
import {selectWindowWidth} from '../../core/state/windowWidth.slice';
import useWidth from '../../hooks/useWidth';

const OurPackage = () => {
  const activeSection = useSelector(selectActiveSectionIndex);
  const windowWidth = useSelector(selectWindowWidth);
  const dispatch = useDispatch();
  const router = useRouter();
  const width = useWidth()

  return (
    <>
      <Slide
        onClick={() => {
          activeSection === 4
            ? dispatch(setActiveSection(-1))
            : dispatch(setActiveSection(4));
        }}>
        <OurPackages
          onClick={() => {
            if (windowWidth == 'mobile') {
              activeSection === 1
                ? dispatch(setActiveSection(-1))
                : dispatch(setActiveSection(1));
            }
          }}>
          <img
            src={balloon.src}
            alt='section main image'
            className='home__package--balloon'
          />

          <p className='main-title'>
            Our
            <br /> packages
          </p>
          <div className='home__package--description'>
            {/* {windowWidth == "desktop" ? ( */}
            <p className='home__package--sub-title'>
              Introducing a New <br />
              Concept of Packages Adaptability
            </p>

            <p className='home__package--content'>
              The provided packages ensure maximum adaptability to seamlessly
              meet each partner's business needs and objectives.
            </p>
          </div>
          {width > 1024 ? null : (
            <img src={scroll_hand.src} className='home__package--hand' />
          )}
        </OurPackages>
      </Slide>

      <Expand expand={activeSection === 4}>
        <PackagesExpanded>
          <img
            className='lamb'
            src={windowWidth == 'desktop' ? lamb.src : mobileLamb.src}
            alt=''
          />

          <PackagesContent>
            <div className='home__package'>
              <div className='home__package--title'>
                <p className='home__package--title-text'>
                  Operations
                  <br />
                  Effectiveness
                </p>
                <span className='home__package--title-small'>Package</span>
              </div>
              <div className='home__package--title'>
                <span className='home__package--title-bar'></span>
                <p className='home__package--title-text'>
                  Operations
                  <br />
                  Maximization
                </p>
                <span className='home__package--title-small'>Package</span>
              </div>
              <div className='home__package--title'>
                <span className='home__package--title-bar'></span>
                <p className='home__package--title-text'>
                  Operations
                  <br />
                  Engagement
                </p>
                <span className='home__package--title-small'>Package</span>
              </div>
              <div className='home__package--title'>
                <span className='home__package--title-bar'></span>
                <p className='home__package--title-text'>
                  Business
                  <br />
                  Intelligence
                </p>
                <span className='home__package--title-small'>Package</span>
              </div>
            </div>

            <div className='home__package--button'>
              <button
                className='home__package--button-showmore'
                onClick={() => {
                  router.push('/OurPackages');
                  dispatch(setActiveSection(-1));
                }}>
                LEARN MORE
              </button>
            </div>
          </PackagesContent>
        </PackagesExpanded>
      </Expand>
    </>
  );
};

export default OurPackage;
