import styled from "styled-components";
import background from "../../../public/image/home/home-background.webp";

export const Wrapper_Home = styled.div`
  background-image: url("${background.src}");
  background-repeat: repeat-x;
  background-size: 50% 100%;
  width: 100vw;
  height: 100vh;
  @media (max-width: 768px) {
    background-size: contain;
    background-position: bottom;
  }
  cursor: default;
  position: relative;
  .mouse__image {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    height: max-content;
    margin: auto;
    bottom: 10%;
    @media (max-width: 1024px) and  (min-width: 769px) {
      filter: brightness(0) invert(1);
    }
    
    @media (max-width: 500px) {
      bottom: calc(15% + 60px);
    }
  }
`;

export const SolidBackground = styled.div`
  background-color: #3a89ad;
  width: 50%;
  align-items: center;
  img {
    max-width: 60%;
    margin-left: 80px;
  }
  padding: 300px 0;
  height: 100vh;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    padding: unset;
    height: 60vh;
    img {
      margin-left: unset;
      margin-top: 70px;
    }
  }

  @media (max-width: 500px) {
    height: calc(60vh - 60px);
  }
`;
