import React from 'react';
import {useRouter} from 'next/router';
import {Expand, Slide} from '../../styles/Home.style';
import aboutus from '../../public/image/about us/about.webp';
import aboutus_mobile from '../../public/image/about us/about_mobile.webp';
import ropes from '../../public/image/about us/ropes.webp';
import ropes_expand_mobile from '../../public/image/about us/ropes_expand_mobile.webp';
import person_expand_mobile from '../../public/image/about us/person_expand_mobile.webp';
import person from '../../public/image/about us/person.webp';
import scroll_hand from '../../public/image/about us/scroll_hand.webp';
import {AboutUsContent, AboutUsExpanded, AboutUsHome} from './Home.style';
import {useSelector, useDispatch} from 'react-redux';
import useWidth from '../../hooks/useWidth';
import {
  selectActiveSectionIndex,
  setActiveSection,
} from '../../core/state/navigation.slice';
import {selectWindowWidth} from '../../core/state/windowWidth.slice';

const AboutUs = () => {
  const activeSection = useSelector(selectActiveSectionIndex);
  const windowWidth = useSelector(selectWindowWidth);
  const dispatch = useDispatch();
  const router = useRouter();
  const width = useWidth()
  return (
    <>
      <Slide
        onClick={() => {
          activeSection === 1
            ? dispatch(setActiveSection(-1))
            : dispatch(setActiveSection(1));
        }}>
        <AboutUsHome
          onClick={() => {
            if (windowWidth == 'mobile') {
              activeSection === 1
                ? dispatch(setActiveSection(-1))
                : dispatch(setActiveSection(1));
            }
          }}>
          {windowWidth == 'desktop' ? (
            <img
              src={aboutus.src}
              alt='section main image'
              className='home__aboutus--ropes'
            />
          ) : (
            <img
              src={aboutus_mobile.src}
              alt='section main image'
              className='home__aboutus--ropes'
            />
          )}
          <p className='main-title'>
            about <br /> us
          </p>
          <div className='home__aboutus--description'>
            {/* {windowWidth == "desktop" ? ( */}
            <p className='home__aboutus--sub-title'>
              THE NUCLEUS OF <br /> STRUCTURAL GROWTH
            </p>
            <p className='home__aboutus--content'>
              Electa is a manpower sourcing company providing methodological
              services that aim to maximize clients’ profitability through the
              implementation of a comprehensive,{' '}
              <span style={{whiteSpace: 'nowrap'}}>analytics-driven</span>{' '}
              operating model.
            </p>
          </div>
          {width > 1024 ? null : (
            <img
              src={scroll_hand.src}
              className='home__aboutus--hand'
              alt='hand'
            />
          )}
        </AboutUsHome>
      </Slide>

      <Expand expand={activeSection === 1}>
        {windowWidth == 'desktop' ? (
          <AboutUsExpanded>
            <img className='ropes' src={ropes.src} alt='ropes' />
            <img className='person' src={person.src} alt='person' />

            <AboutUsContent>
              <div className='home__aboutus--subtitle'>
                <p>
                  Providing Methodological Services,
                  <br />
                  Maximizing Our Partners{"'"} Profitability
                </p>
              </div>
              <div className='home__aboutus'>
                <div className='home__aboutus--title'>
                  <span className='home__aboutus--title-bar'></span>
                  <p className='home__aboutus--title-text'>
                    Our
                    <br />
                    Identity
                  </p>
                </div>
                <div className='home__aboutus--title'>
                  <span className='home__aboutus--title-bar'></span>
                  <p className='home__aboutus--title-text'>
                    Our
                    <br />
                    Methodology
                  </p>
                </div>
                <div className='home__aboutus--title'>
                  <span className='home__aboutus--title-bar'></span>
                  <p className='home__aboutus--title-text'>
                    Our
                    <br />
                    Capabilities
                  </p>
                </div>
              </div>

              <div className='home__aboutus--button'>
                <button
                  className='home__aboutus--button-showmore'
                  onClick={() => {
                    router.push('/aboutUs');
                    dispatch(setActiveSection(-1));
                  }}>
                  LEARN MORE
                </button>
              </div>
            </AboutUsContent>
          </AboutUsExpanded>
        ) : (
          <AboutUsExpanded>
            <img className='ropes' src={ropes_expand_mobile.src} alt='ropes' />
            <img
              className='person'
              src={person_expand_mobile.src}
              alt='person'
            />

            <AboutUsContent>
              <div className='home__aboutus'>
                <div className='home__aboutus--title'>
                  <p className='home__aboutus--title-text'>Our Identity</p>
                </div>
                <span className='home__aboutus--title-bar'></span>
                <div className='home__aboutus--title'>
                  <p className='home__aboutus--title-text'>Our Methodology</p>
                </div>
                <span className='home__aboutus--title-bar'></span>
                <div className='home__aboutus--title'>
                  <p className='home__aboutus--title-text'>Our Capabilities</p>
                </div>
              </div>

              <div className='home__aboutus--button'>
                <button
                  className='home__aboutus--button-showmore'
                  onClick={() => {
                    router.push('/aboutUs');
                    dispatch(setActiveSection(-1));
                  }}>
                  LEARN MORE
                </button>
              </div>
            </AboutUsContent>
          </AboutUsExpanded>
        )}
      </Expand>
    </>
  );
};

export default AboutUs;
