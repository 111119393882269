import React from 'react';
import {Wrapper} from './HRManagement.style';

import Title from '../../../public/image/whyUs/Panoramic/title.webp';
import Hand from '../../../public/image/whyUs/Panoramic/hand.webp';
import People from '../../../public/image/whyUs/Panoramic/people.webp';
import Lamp from '../../../public/image/whyUs/Panoramic/lamp.webp';
import Square from '../../../public/image/whyUs/Panoramic/square.webp';
import Before from '../../../public/image/whyUs/Panoramic/before.webp';
import Pull from '../../../public/image/whyUs/Panoramic/pull.webp';
import smallChain from '../../../public/image/whyUs/Panoramic/small-chain.webp';

import castle from '../../../public/image/whyUs/Panoramic/castl.webp';
import tourch from '../../../public/image/whyUs/Panoramic/tourch.webp';

const HRManagement = () => {
  return (
    <Wrapper
      // style={{backgroundImage: `url(${Bg.src})`}}
      square={Square.src}
      before={Before.src}>
      <div className='HR__upper'>
        <img src={Title.src} alt='' />
      </div>
      <div className='HR__warper'>
        <div className='HR__first'>
          <div className='HR__first__main'>
            <img src={Hand.src} alt='' />
            <div className='HR__first__main__content'>
              <div className='HR__first__main__content__col HR__first__main__content__col--title'>
                <h4>
                  Complete Business
                  <br /> Value Chain
                </h4>
                <p>
                  <span>“LMS”</span> provides a comprehensive HR model that aims
                  to achieve a complete business value chain through the
                  implementation of:
                </p>
              </div>
              <div className='HR__first__main__content__col'>
                <section>
                  <img src={Before.src} alt='' />
                  <div>
                    <h5>Business Requirements</h5>
                    <span>Assessments</span>
                  </div>
                </section>
                <section>
                  <img src={Before.src} alt='' />
                  <div>
                    <h5>Human Assets Development</h5>
                    <span>Strategies</span>
                  </div>
                </section>
              </div>
              <div className='HR__first__main__content__col'>
                <section>
                  <img src={Before.src} alt='' />
                  <div>
                    <h5>Talent Acquisition</h5>
                    <span>Management</span>
                  </div>
                </section>
                <section>
                  <img src={Before.src} alt='' />
                  <div>
                    <h5>Extended Day-to-Day HR Tactical</h5>
                    <span>Management</span>
                  </div>
                </section>
              </div>
              <div className='HR__first__main__content__col'>
                <section>
                  <img src={Before.src} alt='' />
                  <div>
                    <h5 className='nowrap'>Employees Experience </h5>
                    <span>Management</span>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className='HR__first__footer'>
            <p>
              {' '}
              The model aims to deliver a{' '}
              <span className='HR__first__footer--colored'>
                {' '}
                unique value of strategic HR management
              </span>{' '}
              instead of a traditional, tactical one.
            </p>
            <p>
              {' '}
              Our HR professionals empower such model with their diversified
              backgrounds from several industries to form a team that entails
              numerous sub-specializations across various{' '}
              <span className='HR__first__footer--not-colored'>
                {' '}
                HR functions
              </span>
              .
            </p>
          </div>
        </div>
        <div className='HR__second'>
          <div className='HR__second__col--first'>
            <img src={People.src} alt='' />
            <h4>Effective Management of Employee Journey</h4>
            <p>
              Powered by an extended digital infrastructure, we aim to ensure an
              effective management of through our{' '}
              <span style={{fontFamily: 'poppinsBold'}}> SEAL framework</span> that
              digitally traces such journey from a pre-entry phase until the
              exit point in a systematic pattern.
            </p>
          </div>
          <div className='HR__second__col HR__second__col--orange'>
            <div className='HR__second__col--orange--title'>
              <h4>
                Optimized Talent
                <br />
                Acquisition
              </h4>
              <img src={Lamp.src} alt='' />
            </div>
            <p>
              Our talent acquisition pathway ensures the optimal identification
              and acquisition of calibers through the implementation of:
            </p>
          </div>
          <div className='HR__second__col HR__second__col--last'>
            <img src={smallChain.src} alt='' />
            <div className='HR__second__col--last--lower'>
              <section>
                <article>
                  <div className='HR__second__col--last--upper HR__second__col--last--left'>
                    <h5>
                      A Systematic
                      <br />
                      Methodology
                    </h5>
                    <span>of Talents Recruitment</span>
                  </div>
                  <div className='HR__second__col--last--upper HR__second__col--last--right'>
                    <h5>
                      Periodical
                      <br />
                      Evaluation
                    </h5>
                    <span>of Performance During Probation</span>
                  </div>
                </article>
                <article>
                  <div className='HR__second__col--last--left'>
                    <h5>
                      Effective
                      <br />
                      Readiness
                    </h5>
                    <span>Plans</span>
                  </div>
                  <div className='HR__second__col--last--right'>
                    <h5>
                      A Thorough
                      <br />
                      Skill Matrix
                    </h5>
                    <span>Assessment</span>
                  </div>
                </article>
              </section>
              <div className='HR__second__col HR__second__col--last--overlay'></div>
            </div>
          </div>
        </div>

        <div className='HR__third'>
          <div className='HR__third__content'>
            <div className='HR__third__col HR__third__col--title'>
              <img src={Pull.src} alt='' />
              <h4>
                Prioritization of Capabilities <br />
                Development
              </h4>
            </div>
            <div className='HR__third__col HR__third__col--content'>
              <p>
                The <span className='HR__third__col--colored'>“LMS”</span> model
                prioritizes the exploration of professionals’ potentials and the
                development of their capabilities to meet the market’s changing
                needs and seize any potential growth opportunities.
              </p>
              <p>
                <span>“Growing From Within”</span> is a business philosophy that
                Electa Sourcing adopts and implements through the conduction of
                intensive internal trainings and employees development plans.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='HR__overlay'></div> */}
      <img src={castle.src} alt='' className='HR__castle' />
      <img src={tourch.src} alt='' className='HR__tourch' />
    </Wrapper>
  );
};

export default HRManagement;
