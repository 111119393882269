import React from 'react';
import {Wrapper} from './OrganicScalability.style';
import Man from '../../../public/image/WhyUs/Organic/man.webp';
import Title from '../../../public/image/WhyUs/Organic/organic.webp';
import Bg from '../../../public/image/WhyUs/Organic/bg.webp';
import Flag from '../../../public/image/WhyUs/Organic/flag.webp';
import Goal from '../../../public/image/WhyUs/Organic/goal.webp';
import Systematic from '../../../public/image/WhyUs/Organic/systematic.webp';
import Analytics from '../../../public/image/WhyUs/Organic/analytics.webp';

const OrganicScalability = () => {
  return (
    <Wrapper
      style={{backgroundImage: `url(${Bg.src})`}}
      className='OrganicScalability'>
      <div className='OrganicScalability__man'>
        <img src={Man.src} alt='man' />
      </div>
      <div className='OrganicScalability__upper'>
        <div className='OrganicScalability__upper__title'>
          {/* <img src={Title.src} alt='' className='titleanimate' /> */}
          <img src={Title.src} alt='' />
        </div>
      </div>
      <div className='OrganicScalability__lower'>
        <div className='OrganicScalability__lower__section OrganicScalability__lower__section__upper'>
          <article className='OrganicScalability__lower__left OrganicScalability__lower__left__upper'>
            <div className='OrganicScalability__header OrganicScalability__header__left OrganicScalability__header__rectangle-1'>
              <div className='OrganicScalability__header__title'>
                Systematic <br />
                Structure
              </div>
              <div className='OrganicScalability__header__image'>
                <img src={Systematic.src} />
              </div>
            </div>
            <div className='OrganicScalability__content OrganicScalability__content__left'>
              <p>
                The systematic structure of the{' '}
                <span className='OrganicScalability__content__colored'>
                  “LMS”
                </span>{' '}
                model provides your business with the required tools to achieve{' '}
                <span className='OrganicScalability__content__not-colored'>
                  organic, operational scalability.
                </span>
              </p>
              <p>
                We provide your business with an optimized decision-making
                process based on an analytical assessment of each product and
                its effectiveness through our{' '}
                <span className='OrganicScalability__content__not-colored'>
                  product profiling and workflow mapping services.
                </span>
              </p>
            </div>
          </article>
          <article className='OrganicScalability__lower__right OrganicScalability__lower__right__upper'>
            <div className='OrganicScalability__header OrganicScalability__header__right OrganicScalability__header__rectangle-3'>
              <div className='OrganicScalability__header__title '>
                Demonstrated
                <br /> Efficacy
              </div>
              <div className='OrganicScalability__header__image'>
                <img src={Flag.src} />
              </div>
            </div>
            <div className='OrganicScalability__content OrganicScalability__content__right'>
              <p>
                <span className='OrganicScalability__content__colored'>
                  “LMS”{' '}
                </span>{' '}
                provides an expansive, in-house business model that demonstrated
                its efficacy across{' '}
                <span className='OrganicScalability__content__not-colored'>
                  14 departments
                </span>{' '}
                to meet the demands of the growing market and achieve an{' '}
                <span className='OrganicScalability__content__not-colored'>
                  integrated operational breadth for your business.
                </span>
              </p>
            </div>
          </article>
        </div>

        <div className='OrganicScalability__lower__section OrganicScalability__lower__section__down'>
          <article className='OrganicScalability__lower__left OrganicScalability__lower__left__down'>
            <div className='OrganicScalability__header OrganicScalability__header__left OrganicScalability__header__rectangle-2'>
              <div className='OrganicScalability__header__title'>
                Analytics-Driven
                <br /> Management
              </div>
              <div className='OrganicScalability__header__image'>
                <img src={Analytics.src} />
              </div>
            </div>
            <div className='OrganicScalability__content OrganicScalability__content__left'>
              <p>
                The{' '}
                <span className='OrganicScalability__content__not-colored'>
                  Emergency Cushion Management
                </span>{' '}
                model we adopt captures operation management patterns to
                facilitate the management of any unplanned urgencies and
                increase{' '}
                <span className='OrganicScalability__content__not-colored'>
                  responsivity to market changes through an analytics-driven
                  management process.
                </span>
              </p>
              <p>
                We provide your business with live performance tracking tools
                for{' '}
                <span className='OrganicScalability__content__not-colored'>
                  more efficient, day-to-day operations backed up by improved
                  digital structure.
                </span>
              </p>
            </div>
          </article>
          <article className='OrganicScalability__lower__right OrganicScalability__lower__right__down'>
            <div className='OrganicScalability__header OrganicScalability__header__right OrganicScalability__header__rectangle-4'>
              <div className='OrganicScalability__header__title '>
                Insightful
                <br />
                Decision-Making
              </div>
              <div className='OrganicScalability__header__image'>
                <img src={Goal.src} />
              </div>
            </div>
            <div className='OrganicScalability__content OrganicScalability__content__right'>
              <p>
                Empower your insightful decision-making process through
                standardized productivity metrics across all functions and
                detailed productivity optimization reports provided by our{' '}
                <span className='OrganicScalability__content__not-colored OrganicScalability__content__not-colored--bold'>
                  Excalibur System{' '}
                </span>
                in order to ensure the increased productivity.
              </p>
            </div>
          </article>
        </div>

        {/* <div className='OrganicScalability__lower__overlay'></div> */}
      </div>
    </Wrapper>
  );
};

export default OrganicScalability;
