import styled from 'styled-components';

export const Wrapper = styled.div`
  font-family: 'poppinsBold';
  width: ${(props) => (props.setWidth ? '5vw' : 0)};
  visibility: ${(props) => (props.setWidth ? 'visible' : 'hidden')};
  /* height: 100%; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: ${(props) => props.index};
  @media (max-width: 768px) {
    display: none;
  }
  background-color: ${(props) =>
    props.close ? 'transparent' : props.setColor};

  transition: all ease-in-out 450ms;
  ::before {
    background-color: ${(props) => props.color};
    content: '';
    width: calc(5vw);
    height: 100%;
    position: absolute;
    left: -5vw;
    top: 0;
    z-index: -20;
  }

  span.buttontitle {
    color: white;
    cursor: pointer;
    font-size: var(--font-size-normal);
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    width: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 35%;
    position: relative;
    text-transform: uppercase;

    .rotated {
      transform: rotate(180deg);
      &:hover {
        color: #fcf48f;
      }
    }

    @media (max-width: 1300px) {
      font-size: var(--font-size-small);
    }
  }

  span.buttontitle:before {
    transform: translate(-50%, -50%) rotate(-90deg);
    position: absolute;
    top: -0.7vw;
    left: 50%;
    content: '➤';
    font-size: 9px;
  }

  span.buttontitle.close:before {
    bottom: -16px;
    transform: rotate(90deg);
    top: unset;
  }
`;
