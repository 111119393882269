import React from 'react';
import {WrapperAboutExtend} from './WhyUsExpand.style';
import fire from '../../../../public/image/whyUs/fire.webp';
import arrow from '../../../../public/image/whyUs/arrow.webp';

const WhyUsExpand = () => {
  return (
    <WrapperAboutExtend>
      <div className='leftsec'>
        <img className='leftsec__fire' src={fire.src} alt='' />
        <img className='leftsec__arrow' src={arrow.src} alt='' />
        <div className='leftsec__text'>
          <span className='leftsec__text__first'>The</span>
          <span className='leftsec__text__second'>LMS</span>
          <span className='leftsec__text__third'>
            Model <br />
            Guarantees
          </span>
        </div>
      </div>
    </WrapperAboutExtend>
  );
};

export default WhyUsExpand;
