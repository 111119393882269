import React, {useState, useEffect} from 'react';
import {SuccessMessage, Wrapper} from '../../styles/ContactUs.style';
import bg from '../../public/image/contact-us/helb.webp';
import fdeBg from '../../public/image/contact-us/helb-faeded.webp';
import linkedin from '../../public/image/contact-us/Linkedin.svg';
import boat from '../../public/image/contact-us/boat.webp';
import {emailRegex, siteRegex} from '../../util/regex';
import check from '../../public/image/contact-us/check.png';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [openSuccessMessage, setSuccessMessage] = useState(false);
  useEffect(() => {
    window.innerWidth <= 500 && setMobile(true);
  }, []);
  const handelSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email || !phone || !website || !message) {
      setError(true);
      return;
    }
    setError(false);
    try {
      const data = {name, email, phone, website, message};
      await fetch('/api/formSubmit', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data),
      });
      setName('');
      setEmail('');
      setPhone('');
      setWebsite('');
      setMessage('');
      setSuccessMessage(true);
      setTimeout(() => {
        setSuccessMessage(false);
      }, 3000);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Wrapper>
        <div className='contactUs--left'>
          <h2 className='contactUs--left__title'>
            Contact <br />
            Us
          </h2>
          <img
            className='contactUs--left__image'
            src={bg.src}
            alt='main background'
          />
          <div className='contactUs--left__content'>
            <h4 className='contactUs--left__content-h4 contactUs--left__content-pc'>
              A TRUSTWORTHY BUSINESS
              <br />
              PARTNERSHIP AWAITS YOU
            </h4>
            <h4 className='contactUs--left__content-h4 contactUs--left__content-mobile'>
              A TRUSTWORTHY <br /> BUSINESS PARTNERSHIP <br /> AWAITS YOU
            </h4>
            <p>
              Our manpower sourcing services aim to achieve a successful
              business partnership through the implementation of a
              comprehensive,{' '}
              <span style={{whiteSpace: 'nowrap'}}>analytics-driven</span>{' '}
              operating model.
            </p>
          </div>
        </div>

        <div className='contactUs--right'>
          <img
            className='contactUs--right__image-background'
            src={fdeBg.src}
            alt=''
          />
          <img
            className='contactUs--right__image-footer'
            src={boat.src}
            alt=''
          />
          <div className='contactUs--right--content'>
            <h3>The Nucleus {isMobile && <br />} of Structural Growth</h3>
            <h4>
              The Business Growth You Have{isMobile && <br />} Been Aiming for
              Is One Step Away
            </h4>
            <h5>
              For further details and all business-related inquiries, make sure
              to contact us by filling the form below.
            </h5>
            <form onSubmit={handelSubmit}>
              {/* name */}
              <div className='contactUs--right--content__form-controller'>
                <input
                  type='text'
                  placeholder='Company Name'
                  value={name}
                  className={`${error && !name && 'form__error'}`}
                  onChange={(e) => setName(e.target.value)}
                />
                <small>{error && !name && 'name is required'}&nbsp;</small>
              </div>
              {/* email */}
              <div className='contactUs--right--content__form-controller'>
                <input
                  type='email'
                  placeholder='Email'
                  value={email}
                  className={`${
                    error &&
                    (!email || email.match(emailRegex) == null) &&
                    'form__error'
                  }`}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <small>{error && !email && 'email is required'}</small>
                <small>
                  {error &&
                    email &&
                    email.match(emailRegex) == null &&
                    'please provide valid email'}
                  &nbsp;
                </small>
              </div>
              {/* phone */}
              <div className='contactUs--right--content__form-controller'>
                <input
                  type='text'
                  placeholder='Phone'
                  value={phone}
                  className={`${error && !phone && 'form__error'}`}
                  onChange={(e) => {
                    if (e.target.value.length === 0) {
                      setPhone('');
                      return;
                    }
                    if (
                      e.target.value[e.target.value.length - 1].match(/^[0-9]/)
                    )
                      setPhone(e.target.value);
                  }}
                />
                <small>{error && !phone && 'phone is required'}&nbsp;</small>
              </div>
              {/* website */}
              <div className='contactUs--right--content__form-controller'>
                <input
                  type='text'
                  placeholder='Website'
                  value={website}
                  className={`${
                    error &&
                    (!website || website.match(siteRegex) == null) &&
                    'form__error'
                  }`}
                  onChange={(e) => setWebsite(e.target.value)}
                />
                <small>{error && !website && 'website is required'}</small>
                <small>
                  {error &&
                    website &&
                    website.match(siteRegex) == null &&
                    'please provide valid website'}
                  &nbsp;
                </small>
              </div>
              {/* message */}
              <div className='contactUs--right--content__form-controller contactUs--right--content--full-grid'>
                <textarea
                  placeholder='Message'
                  maxLength={1000}
                  value={message}
                  className={`${error && !message && 'form__error'}`}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <small>
                  {error && !message && 'message is required'}&nbsp;
                </small>
              </div>
              <button>Submit</button>
            </form>
            <div className='contactUs--right--content__footer'>
              <h5>EGYPT OPERATION</h5>
              {isMobile ? (
                <div className='contactUs--right--content__footer--mobile__address'>
                  <span className='contactUs--right--content__footer--mobile__address--bold'>
                    Office 1:
                  </span>
                  <span>
                    28, 32 Ibn Hawqal Street, San Stefano, Alexandria – Egypt
                  </span>
                  <span className='contactUs--right--content__footer--mobile__address--bold'>
                    Office 2:
                  </span>
                  <span>Fahmy Wissa Street, Loran, Alexandria – Egypt</span>
                  <span className='contactUs--right--content__footer--mobile__address--bold'>
                    Office 3:
                  </span>
                  <span>Zulfaqar Street, Gianaklis, Alexandria – Egypt</span>
                </div>
              ) : (
                <>
                  <div className='contactUs--right--content__footer--office'>
                    <span>Office 1:</span>
                    <span>Office 2:</span>
                    <span>Office 3:</span>
                  </div>
                  <div className='contactUs--right--content__footer--address'>
                    <span>
                      28, 32 Ibn Hawqal Street, San Stefano
                      <br />
                      Alexandria – Egypt
                    </span>
                    <span>
                      Fahmy Wissa Street, Loran
                      <br />
                      Alexandria – Egypt
                    </span>
                    <span>
                      Zulfaqar Street, Gianaklis
                      <br />
                      Alexandria – Egypt
                    </span>
                  </div>
                </>
              )}

              <div className='contactUs--right--content__footer--contact'>
                <div className='contactUs--right--content__footer--contact--info'>
                  <span className='contactUs--right--content__footer--contact--info--colored'>
                    Email:
                  </span>
                  <span> Customer.Care@electa-Sourcing.com</span>
                </div>
                <div className='contactUs--right--content__footer--contact--info'>
                  <span className='contactUs--right--content__footer--contact--info--colored'>
                    VISIT US{' '}
                    <span style={{color: '#fff'}}> on Our LinkedIn Page</span>:
                  </span>
                  <a
                    href='https://www.linkedin.com/company/82076256/admin/'
                    target='_blank'
                    rel='noreferrer'>
                    {' '}
                    <img src={linkedin.src} alt='' />
                  </a>
                </div>
              </div>
              <div className='contactUs--right--content__footer--address book__now'>
                <p>
                  Do you want to book an appointment with one of our
                  representatives?
                </p>
                <button>
                  <a
                    href='https://calendar.app.google/qBHKaUPsJpcWy55E6'
                    target='_blank'
                    rel='noreferrer'>
                    Book now
                  </a>
                </button>
              </div>
            </div>
          </div>
          {openSuccessMessage && (
            <SuccessMessage>
              <div>
                <img src={check.src} alt='' />
                Thanks for submitting your contact information. We shall contact
                you shortly!
              </div>
            </SuccessMessage>
          )}
        </div>
      </Wrapper>
    </>
  );
};

export default ContactUs;
