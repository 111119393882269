import React from 'react';
import {Expand, Slide} from '../../styles/Home.style';
import {
  selectActiveSectionIndex,
  setActiveSection,
} from '../../core/state/navigation.slice';
import question from '../../public/image/services/question.webp';
import question_opacity from '../../public/image/services/question_opacity.webp';
import question_opacity_mobile from '../../public/image/services/question_opacity_mobile.webp';
import factory from '../../public/image/services/factory.webp';
import factory_mobile from '../../public/image/services/factory_mobile.webp';
import factory_tablet from '../../public/image/services/factory_tablet.webp';
import scroll_hand from '../../public/image/services/hand.webp';
import {useDispatch, useSelector} from 'react-redux';
import {
  OurServicesContent,
  OurServicesExpanded,
  OurServicesHome,
} from './OurServices.style';
import {selectWindowWidth} from '../../core/state/windowWidth.slice';
import {useRouter} from 'next/router';
import useWidth from '../../hooks/useWidth';
const OurServices = () => {
  const width = useWidth();
  const router = useRouter();
  const activeSection = useSelector(selectActiveSectionIndex);
  const dispatch = useDispatch();
  const windowWidth = useSelector(selectWindowWidth);
  return (
    <>
      <Slide
        onClick={() => {
          activeSection === 3
            ? dispatch(setActiveSection(-1))
            : dispatch(setActiveSection(3));
        }}>
        <OurServicesHome
          onClick={() => {
            if (windowWidth == 'mobile') {
              activeSection === 3
                ? dispatch(setActiveSection(-1))
                : dispatch(setActiveSection(3));
            }
          }}>
          <img src={question.src} alt='' className='ourservices__question' />

          <p className='main-title'>
            Our <br /> services
          </p>

          <div className='home__ourservices--description'>
            {windowWidth == 'desktop' || windowWidth === 'tablet' ? (
              <p className='home__ourservices--sub-title'>
                THE INTEGRATION OF <br /> PROVEN EFFICIENCY
              </p>
            ) : (
              <p className='home__ourservices--sub-title'>
                THE INTEGRATION <br /> OF PROVEN <br /> EFFICIENCY
              </p>
            )}
            <p className='home__ourservices--content'>
              Our manpower sourcing model achieves a business equilibrium
              between function-based dynamism and operational standardization
              across functions to ensure the effectiveness of each service on an
              operational level.
            </p>
          </div>
          {width > 1024 ? null : (
            <img src={scroll_hand.src} className='home__ourservices--hand' />
          )}
        </OurServicesHome>
      </Slide>

      <Expand expand={activeSection === 3}>
        <OurServicesExpanded>
          {windowWidth == 'desktop' ? (
            <>
              <img src={factory.src} alt='' className='factory' />
              <img
                src={question_opacity.src}
                alt=''
                className='question_opacity'
              />
            </>
          ) : (
            <>
              {width >= 500 && width <= 768 ? (
                <img src={factory_tablet.src} alt='' className='factory' />
              ) : (
                <img src={factory_mobile.src} alt='' className='factory' />
              )}

              <img
                src={question_opacity_mobile.src}
                alt=''
                className='question_opacity'
              />
            </>
          )}

          <OurServicesContent>
            <div className='home__ourservices'>
              <div className='home__ourservices--container'>
                <p className='home__ourservices--container-title'>
                  Creative
                  <br />
                  <span>Functions</span>
                </p>
                <p className='home__ourservices--container-content'>
                  Creative functions and specialties, such as 2D, 3D, and motion
                  graphics designing, the LMS model ensures that our
                  professionals’ productivity is optimized through the
                  implementation of our methodological capacity management
                  system, Excalibur, that standardizes productivity metrics
                  across all professionals.
                </p>
              </div>
              <div className='home__ourservices--container'>
                <p className='home__ourservices--container-title'>
                  Digital
                  <br />
                  <span>Solutions</span>
                </p>
                <p className='home__ourservices--container-content'>
                  Digital solutions specialties, the effectiveness of{' '}
                  <span style={{whiteSpace: 'nowrap'}}>day-to-day</span>{' '}
                  operations is guaranteed through the implementation of
                  performance live tracking tools and the improved digital
                  infrastructure that facilitates the timely delivery of
                  numerous deliverables.
                </p>
              </div>
            </div>
            <div className='home__ourservices--button'>
              <button
                className='home__ourservices--button-showmore'
                onClick={() => {
                  router.push('/ourService');
                  dispatch(setActiveSection(-1));
                }}>
                LEARN MORE
              </button>
            </div>
          </OurServicesContent>
        </OurServicesExpanded>
      </Expand>
    </>
  );
};

export default OurServices;
