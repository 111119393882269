import React from 'react';
import {Wrapper} from './WhyUsButton.style';
const WhyUsButton = ({
  title,
  background,
  setColor,
  setReverseColor,
  close,
  last,
  setWidth,
  onClick,
  index,
}) => {
  return (
    <Wrapper
      style={{backgroundImage: `url(${background})`}}
      setColor={setColor}
      setReverseColor={setReverseColor}
      setWidth={setWidth}
      onClick={onClick}
      last={last}
      close={close}
      index={index}>
      <span className={`buttontitle ${close ? 'close' : null}`}>
        <span className='rotated'>{title}</span>
      </span>
    </Wrapper>
  );
};

export default WhyUsButton;
