import React from "react";
import { WhyUsSideWrapper } from "./WhuUsSide.style";
import arrowImage from "../../../../public/image/whyUs/arrow-main.webp";
import scroll_hand from "../../../../public/image/whyUs/mobile/click.webp";
import { useSelector } from "react-redux";
import Link from "next/link";
import { selectWindowWidth } from "../../../../core/state/windowWidth.slice";
import useWidth from "../../../../hooks/useWidth";

const WhuUsSide = ({ checkWidth }) => {
  const windowWidth = useSelector(selectWindowWidth);
  const width = useWidth()

  return (
    <WhyUsSideWrapper>
      {checkWidth == "mobile" ? <Link href="/whyUs"> </Link> : null}
      <div className="whyUs__warper">
        <img src={arrowImage.src} alt="section main image" className="whyUs__image" />
        <div className="whyUs__title">
          <div className="whyUs__title--1">Why Us?</div>
          <div className="whyUs__title--2">The</div>
          <div className="whyUs__title--3">LMS</div>
          <div className="whyUs__title--4">Model</div>
        </div>
        <div className="whyUs__content">
          <h3 className="whyUs__content--title">
            The Power of
            <br /> a Proven Methodology
          </h3>
          <p className="whyUs__content--body">
            The systematic structure of the <span style={{ fontFamily: "poppinsBold" }}>“LMS”</span> model provides your business with the required tools
            to achieve organic, operational scalability and to attain your business objectives in a timely,{" "}
            <span style={{ whiteSpace: "nowrap" }}>cost-efficient</span> manner.
          </p>
        </div>
      </div>
      {width > 1024 ? null : <img src={scroll_hand.src} className="home__whyus--hand" />}
    </WhyUsSideWrapper>
  );
};

export default WhuUsSide;
